import React from "react";
import PropTypes from "prop-types";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";

class BreadCrumbs extends React.PureComponent {
  static propTypes = {
    items: PropTypes.array.isRequired
  };

  render() {
    if (this.props.items.length > 1)
      return (
        <Breadcrumb>
          {this.props.items.map(({ active, path, title }, index) => (
            <BreadcrumbItem key={index.toString()}>
              {active ? (
                title
              ) : (
                <Link className="link" to={path}>
                  {title}
                </Link>
              )}
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
      );
    return null;
  }
}

export default BreadCrumbs;
