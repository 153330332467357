import React from "react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import PropTypes from "prop-types";
import { Input } from "reactstrap";

class ZonesSelect extends React.PureComponent {
  static propTypes = {
    city: PropTypes.string,
    zonesList: PropTypes.array,
    setRef: PropTypes.func,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    city: null,
    zonesList: [],
    setRef: null,
    defaultValue: null,
    onChange: () => {},
    isDisabled: false,
  };

  zone = null;

  setZone = (ref) => {
    if (ref) {
      this.zone = ref;
      this.props.setRef(ref);
    }
  };

  render() {
    return (
      <Input
        innerRef={this.setZone}
        id="zone"
        name="zone"
        type="select"
        value={this.props.defaultValue}
        onChange={this.props.onChange}
        disabled={this.props.isDisabled}
      >
        <option key={"hjjjjh"} value={null}>
          Séléctionner
        </option>
        {this.props.zonesList.map((z) => (
          <option key={z.id} value={z.id}>
            {z.name}
          </option>
        ))}
      </Input>
    );
  }
}

const ZONES = gql`
  query zonesList {
    zonesList {
      id
      name
    }
  }
`;

export default graphql(ZONES, {
  props: ({ data: { zonesList } }) => ({
    zonesList,
  }),
})(ZonesSelect);
