import React from "react";
import { Card } from "reactstrap";

import Logo from "./Logo";

const img = new Image();

const Fallback = () => {
  img.src = "./images/loader2.gif";
  return (
    <div className="fallback-spinner logo__withpadding">
      <Card className="text-center">
        <Logo className="mb-sm-2 position-static  logo__loader" />
        <div className="p-sm-3">
          <img src={img.src} alt="loader" height={150} />
        </div>
      </Card>
    </div>
  );
};

export default Fallback;
