import {
  COUNT_DRIVER_REQUEST,
  COUNT_MENU_REQUEST,
  COUNT_NOTIFICATION,
  COUNT_STORE_REQUEST,
  KITCHEN_CATEGORIES,
  MENU_CATEGORIES,
  SUB_CATEGORIES,
  STORE_CATEGORIES,
} from "./queries";
import { IN_PROGRESS, PENDING_REQUEST } from "./utils/constants";

export default {
  Query: {
    isLoggedIn: (_, {}, { getCacheKey }) =>
      getCacheKey({ __typename: "IsLoggedIn" }),
  },
  Mutation: {
    decrementRequestNumber: (_, { type }, { cache }) => {
      const queryParams = {
        variables: {
          status: IN_PROGRESS,
          startDate: null,
          endDate: null,
          value: null,
        },
      };

      let key = null;
      switch (type) {
        case "menuRequest":
          queryParams.query = COUNT_MENU_REQUEST;
          key = "countMenuRequests";
          break;
        case "driverRequest":
          queryParams.query = COUNT_DRIVER_REQUEST;
          key = "countDriverRequests";
          break;
        case "storeRequest":
          queryParams.query = COUNT_STORE_REQUEST;
          queryParams.variables.status = PENDING_REQUEST;
          key = "countStoreRequests";
          break;
        default:
      }
      const data = cache.readQuery(queryParams);
      if (data[key] && data[key] - 1 >= 0) {
        cache.writeQuery({
          ...queryParams,
          data: {
            [key]: data[key] - 1,
          },
        });
      }
      return null;
    },
    incrementNotificationsLength: (_, { looked }, { cache }) => {
      const audio = new Audio("/notification.mp3");
      audio.play();

      const queryParams = {
        query: COUNT_NOTIFICATION,
        variables: {
          looked,
        },
      };

      const { countNotifications } = cache.readQuery(queryParams);

      cache.writeQuery({
        ...queryParams,
        data: {
          countNotifications: countNotifications + 1,
        },
      });
      return null;
    },
    updateNotificationsLength: (_, { length, looked }, { cache }) => {
      cache.writeQuery({
        query: COUNT_NOTIFICATION,
        variables: {
          looked,
        },
        data: {
          countNotifications: length,
        },
      });
      return null;
    },
    setToken: (_, { token }, { cache }) => {
      const bearerToken = token ? `Bearer ${token}` : null;
      localStorage.setItem("token", bearerToken);
      cache.writeData({
        data: {
          isLoggedIn: {
            __typename: "IsLoggedIn",
            isLoggedIn: !!token,
          },
        },
      });
      return null;
    },
    updateKitchenCategory: (_, { kitchenCategory, isNew }, { cache }) => {
      const queryParams = {
        query: KITCHEN_CATEGORIES,
        variables: {
          value: null,
        },
      };

      const { kitchenCategories } = cache.readQuery(queryParams);
      if (kitchenCategories) {
        let newKitchenCategories = [];
        if (!isNew) {
          const index = kitchenCategories.findIndex(
            (c) => c.id === kitchenCategory.id
          );
          if (index !== -1)
            newKitchenCategories = [
              ...kitchenCategories.slice(0, index),
              {
                ...kitchenCategory,
              },
              ...kitchenCategories.slice(index + 1),
            ];
        } else newKitchenCategories = [...kitchenCategories, kitchenCategory];
        cache.writeQuery({
          ...queryParams,
          data: {
            kitchenCategories: newKitchenCategories,
          },
        });
      }
      return null;
    },
    updateMenuCategories: (_, { menuCategory, isNew }, { cache }) => {
      const queryParams = {
        query: MENU_CATEGORIES,
        variables: {
          value: null,
        },
      };
      const res = cache.readQuery(queryParams);
      if (res.menuCategories) {
        let newMenuCategories = [];
        if (!isNew) {
          const index = res.menuCategories.findIndex(
            (c) => c.id === menuCategory.id
          );
          if (index !== -1)
            newMenuCategories = [
              ...res.menuCategories.slice(0, index),
              {
                ...res.menuCategories,
              },
              ...res.menuCategories.slice(index + 1),
            ];
        } else newMenuCategories = [...res.menuCategories, menuCategory];
        cache.writeQuery({
          ...queryParams,
          data: {
            menuCategories: newMenuCategories,
          },
        });
      }
      return null;
    },
    updateStoreCategories: (_, { storeType, isNew }, { cache }) => {
      const queryParams = {
        query: STORE_CATEGORIES,
        variables: {
          value: null,
        },
      };
      const res = cache.readQuery(queryParams);
      if (res.storeTypes) {
        let newStoreTypesCategories = [];
        if (!isNew) {
          const index = res.storeTypes.findIndex((c) => c.id === storeType.id);
          if (index !== -1)
            newStoreTypesCategories = [
              ...res.storeTypes.slice(0, index),
              {
                ...res.storeTypes,
              },
              ...res.storeTypes.slice(index + 1),
            ];
        } else newStoreTypesCategories = [...res.storeTypes, storeType];
        cache.writeQuery({
          ...queryParams,
          data: {
            storeTypes: newStoreTypesCategories,
          },
        });
      }
      return null;
    },
    updateSubCatgeories: (_, { subCategory, isNew }, { cache }) => {
      const queryParams = {
        query: SUB_CATEGORIES,
        variables: {
          value: null,
          menuCategory: null,
        },
      };
      const res = cache.readQuery(queryParams);
      if (res.subCategories) {
        let newSubCategories = [];
        if (!isNew) {
          const index = res.subCategories.findIndex(
            (s) => s.id === subCategory.id
          );
          if (index !== -1)
            newSubCategories = [
              ...res.subCategories.slice(0, index),
              {
                ...res.subCategories,
              },
              ...res.subCategories.slice(index + 1),
            ];
        } else newSubCategories = [...res.subCategories, subCategory];
        cache.writeQuery({
          ...queryParams,
          data: {
            subCategories: newSubCategories,
          },
        });
      }
      return null;
    },
    inscrementMenuRequestsLength: (_, { inc }, { cache }) => {
      const queryParams = {
        query: COUNT_MENU_REQUEST,
        variables: {
          startDate: null,
          endDate: null,
          value: null,
          status: IN_PROGRESS,
        },
      };
      const { countMenuRequests } = cache.readQuery(queryParams);
      cache.writeQuery({
        ...queryParams,
        data: {
          countMenuRequests: countMenuRequests + inc,
        },
      });
      return null;
    },
  },
};
