/* import locations from "countries-cities"; */
import gql from "graphql-tag";
import "moment/locale/fr";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { compose, graphql } from "react-apollo";
import Container from "../../components/common/Container";
import { withRouter } from "react-router-dom";
import { Badge, Col, ListGroup, Row, Label } from "reactstrap";
import {
  invalidDescription,
  invalidEmail,
  registration,
} from "../../errorMessages.json";
import Alert from "../../utils/Alert";
import Button from "../../components/common/Button";
import { formatMobile, formattingMobile } from "../../utils/functions";

import GraphQLResult from "../../components/common/GraphQLResult";
import AutoCompleteInput from "../../components/request/newRequestDetails/AutoCompleteInput";
import UserUpdate from "../../components/user/UserUpdate";
import {
  titles,
  FRENCH_DATE_FORMAT,
  EMAIL_REGEX,
  MOBILE_REGEX_INCLUSIVE,
  NAME_REGEX,
  REAL_NAME_REGEX,
  SEANCE,
  SIREN_REGEX,
  VAT_REGEX,
} from "../../utils/constants";
import CustomInput from "../../components/request/newRequestDetails/CustomInput";
import moment from "moment";
const defaultPdfImage = "/images/pdf.png";
const defaultErrors = {
  name: null,
  vat: null,
  address: null,
  siren: null,
  kitchenCategory: null,
  type: null,
  mobile: null,
  email: null,
  ownerName: null,
  ownerMobile: null,
  ownerEmail: null,
  description: null,
  preparationTime: null,
};
class DriverStripe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      docFront: null,
      docBack: null,
      kbis: null,
      loading: false,
      error: null,
      errors: {
        ...defaultErrors,
      },

      values: null,
      breadCrumbs: [
        {
          title: titles.STRIPE_DRIVER,
          path: "/livreurs/pages/1",
        },
      ],
    };
  }

  setRef = (ref) => {
    if (ref) this[ref.name] = ref;
  };

  validateStoreFields = (name, value) => {
    const { values } = this.state;
    switch (name) {
      case "name":
        return value == null /* || !NAME_REGEX.test(value) */
          ? values.name
          : null;
      case "email":
        return value == null || !EMAIL_REGEX.test(value) ? values.email : null;
      case "mobile":
        return value == null || !MOBILE_REGEX_INCLUSIVE.test(value)
          ? values.mobile
          : null;
      case "vat":
        return value == null || !VAT_REGEX.test(value) ? values.vat : null;
      case "siren":
        return value == null || !SIREN_REGEX.test(value) ? values.siren : null;

      case "ownerFirstName":
        return value == null || !REAL_NAME_REGEX.test(value)
          ? values.name
          : null;
      case "ownerLastName":
        return value == null || !REAL_NAME_REGEX.test(value)
          ? values.name
          : null;
      case "ownerEmail":
        return value == null || !EMAIL_REGEX.test(value) ? values.email : null;
      case "ownerMobile":
        return value == null || !MOBILE_REGEX_INCLUSIVE.test(value)
          ? values.mobile
          : null;

      default:
        return null;
    }
  };

  componentDidUpdate(prevProps) {
    const { driver } = this.props;
    if (!prevProps.driver && driver) this.setBreadCrumbs(driver);
    // if (!prevProps.driver && driver) this.subscribeToContractUpload();
  }
  renderFilePreview = (path, toSend) => {
    if (!path || path.length < 1) return null;
    if (
      path.endsWith(".pdf") ||
      (toSend && toSend.type === "application/pdf")
    ) {
      return (
        <a href={path} target="_blank" rel="noopener noreferrer">
          <img
            src={defaultPdfImage}
            style={{
              height: "120px",
            }}
          />
        </a>
      );
    } else
      return (
        <a href={path} target="_blank" rel="noopener noreferrer">
          <img
            src={path}
            style={{
              width: "120px",
              height: "120px",
              backgroundSize: "cover",
            }}
          />
        </a>
      );
  };
  setBreadCrumbs = (driver = this.props.driver) => {
    this.setState(({ breadCrumbs }) => ({
      breadCrumbs: [
        ...breadCrumbs,
        {
          title:
            driver &&
            driver.profile &&
            driver.profile.firstName + " " + driver.profile.lastName,
          active: true,
        },
      ],
    }));
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.driver) {
      const {
        driver: {
          id,
          code,
          status,
          dob,
          profile: { firstName, lastName, birthdate },
          mobile,
          email,
          address: { address, city, country, zipCode },
          siren,
          vat,
          nidFront,
          nidBack,
          acceptedBy: {
            profile: { firstName: adminFirstName, lastName: adminLastName },
          },
          description,
          urlMenu,
          rib,
        },
      } = nextProps;
      const values = {
        id,
        name: firstName + " " + lastName,
        vat,
        address,
        city,
        rib,
        country,
        birthdate: moment(birthdate).format("YYYY-MM-DD"),
        zipCode,
        siren,
        urlMenu: urlMenu ? urlMenu : "https://halalkoom.com",
        dob,
        mobile: mobile ? formattingMobile(mobile) : "",
        email: email ? email : "",
        ownerFirstName: firstName,
        ownerLastName: lastName,
        ownerMobile: mobile ? formattingMobile(mobile) : "",
        ownerEmail: email ? email : "",
        adminFirstName,
        adminLastName,
        addressOwner: address,
        cityOwner: city,
        countryOwner: country,
        zipCodeOwner: zipCode,
        status,
        description,
        code,
        nidFront,
        nidBack,
      };
      this.setState({
        values,
      });
    }
  }
  renderFilePreview = (path, toSend) => {
    if (!path || path.length < 1) return null;
    if (
      path.endsWith(".pdf") ||
      (toSend && toSend.type === "application/pdf")
    ) {
      return (
        <a href={path} target="_blank" rel="noopener noreferrer">
          <img
            src={defaultPdfImage}
            style={{
              height: "120px",
            }}
          />
        </a>
      );
    } else
      return (
        <a href={path} target="_blank" rel="noopener noreferrer">
          <img
            src={path}
            style={{
              width: "120px",
              height: "120px",
              backgroundSize: "cover",
            }}
          />
        </a>
      );
  };
  handleChangeAddress = ({ address, city, country, zipCode }) => {
    const values = {
      ...this.state.values,
      address,
      city,
      country,
      zipCode,
    };
    this.setState({ values });
  };
  handleChangeAddressOwner = ({ address, city, country, zipCode }) => {
    const values = {
      ...this.state.values,
      addressOwner: address,
      cityOwner: city,
      countryOwner: country,
      zipCodeOwner: zipCode,
    };

    this.setState({ values });
  };
  handleFileUpload = (e) => {
    e.persist();
    const { name } = e.target;
    if (e.target.files && e.target.files[0])
      this.setState({
        [name]: e.target.files[0],
      });
  };

  handleChange = (e) => {
    e.persist();
    const { name, value, type } = e.target;
    const updatedError = this.validateStoreFields(name, value);

    this.setState((prevState) => ({
      values: {
        ...prevState.values,
        [name]:
          type === "number"
            ? parseInt(value)
            : type === "date"
            ? moment(value).valueOf()
            : value,
      },

      errors: {
        ...prevState.errors,
        [name]: updatedError,
      },
    }));
  };

  submit = () => {
    this.setState({ loading: true });
    const {
      id,
      name,
      address,
      addressOwner,
      city,
      cityOwner,
      country,
      countryOwner,
      email,
      mobile,
      ownerEmail,
      ownerFirstName,
      ownerLastName,
      ownerMobile,
      rib,
      description,
      siren,
      urlMenu,
      vat,
      zipCode,
      zipCodeOwner,
      nidFront,
      birthdate,
      nidBack,
    } = this.state.values;

    const errors = {
      ...defaultErrors,
    };

    if (name == null || !NAME_REGEX.test(name)) errors.name = registration.name;
    if (rib == null || rib.trim().length < 3) errors.rib = registration.rib;
    if (email == null || !EMAIL_REGEX.test(email)) errors.email = invalidEmail;
    if (mobile == null) errors.mobile = registration.mobile;
    if (vat == null || !VAT_REGEX.test(vat)) errors.vat = registration.vat;
    if (siren == null || typeof siren !== "number")
      errors.siren = registration.siren;
    if (ownerFirstName == null || !REAL_NAME_REGEX.test(ownerFirstName))
      errors.ownerFirstName = registration.name;
    if (ownerLastName == null || !REAL_NAME_REGEX.test(ownerLastName))
      errors.ownerLastName = registration.name;
    if (ownerEmail == null) errors.ownerEmail = invalidEmail;
    if (ownerMobile == null) errors.ownerMobile = registration.mobile;
    if (address == null || !address.length)
      errors.address = registration.address;
    if (city == null || !city.length) errors.addres = registration.address;
    if (country == null || !country.length)
      errors.address = registration.address;
    if (zipCode == null || typeof zipCode !== "number")
      errors.address = registration.address;
    if (addressOwner == null || !addressOwner.length)
      errors.addressOwner = registration.address;
    if (cityOwner == null || !cityOwner.length)
      errors.addressOwner = registration.address;
    if (countryOwner == null || !countryOwner.length)
      errors.addressOwner = registration.address;
    if (zipCodeOwner == null || typeof zipCodeOwner !== "number")
      errors.addressOwner = registration.address;
    if (birthdate == null || !birthdate)
      errors.dob = "Il faut choisir la date de naissance";
    if (
      !errors.name &&
      !errors.email &&
      !errors.mobile &&
      !errors.vat &&
      !errors.siren &&
      !errors.ownerFirstName &&
      !errors.ownerLastName &&
      !errors.ownerEmail &&
      !errors.ownerMobile &&
      !errors.address &&
      !errors.addressOwner &&
      !errors.rib &&
      !errors.dob &&
      !this.state.loading
    ) {
      this.props
        .createLinkedAcount({
          variables: {
            input: {
              id,
              name,
              address,
              addressOwner,
              city,
              cityOwner,
              country,
              dob: {
                year: parseInt(
                  moment(birthdate)
                    .format("YYYY-MM-DD")
                    .split("-")[0]
                ),
                month: parseInt(
                  moment(birthdate)
                    .format("YYYY-MM-DD")
                    .split("-")[1]
                ),
                day: parseInt(
                  moment(birthdate)
                    .format("YYYY-MM-DD")
                    .split("-")[2]
                ),
              },
              countryOwner,
              email,
              mobile: formattingMobile(mobile),
              ownerEmail,
              ownerFirstName,
              ownerLastName,
              ownerMobile: formattingMobile(mobile),
              rib,
              description,
              siren,
              urlMenu,
              vat,
              zipCode,
              zipCodeOwner,
              isStore: false,
              nidFront,
              nidBack,
            },
          },
        })
        .then((res) => {
          console.log(res);
          if (res) {
            Alert.success("Le compte stripe a été crée avec succès");
            this.setState({ loading: false, errors: defaultErrors });
            this.props.history.goBack();
          }
        })
        .catch((e) => {
          if (e && e.graphQLErrors && e.graphQLErrors[0])
            Alert.error(e.graphQLErrors[0].message);
          else Alert.error("Veuillez réessayer");
          this.setState({ loading: false });
        });
    } else {
      this.setState({
        loading: false,
        errors: {
          ...errors,
        },
      });
    }
  };

  render() {
    const {
      error,
      loading,

      isDisabled,

      driver,
    } = this.props;
    const { errors, values } = this.state;
    return (
      <Container breadCrumbs={this.state.breadCrumbs}>
        <GraphQLResult
          error={error}
          loading={loading}
          emptyResult={!loading && !driver}
          emptyResultMsg={"Ce restaurant est introuvable."}
          data={driver}>
          {values && (
            <div style={{ backgroundColor: "white", padding: 15 }}>
              <h5 className="text-uppercase font-weight-bold mt-3 mb-3">
                {"Livreur" + "  "}
                <Badge color="secondary">{values.code}</Badge>
              </h5>
              <ListGroup tag="div" style={{ margin: "5px" }}>
                <Row form>
                  <Col xs={6} sm={4}>
                    <CustomInput
                      label="Nom complet du Livreur"
                      type="text"
                      name="name"
                      id="name"
                      handleChange={this.handleChange}
                      value={values.name}
                      maxLength={50}
                      invalid={!!errors.name}
                      feedBack={errors.name}
                      isDisabled={isDisabled}
                    />
                  </Col>
                  <Col xs={6} sm={4}>
                    <CustomInput
                      label="Email"
                      type="email"
                      name="email"
                      id="email"
                      handleChange={this.handleChange}
                      value={values.email}
                      maxLength={30}
                      invalid={!!errors.email}
                      feedBack={errors.email}
                      isDisabled={isDisabled}
                    />
                  </Col>
                  <Col xs={6} sm={4}>
                    <CustomInput
                      label="Téléphone"
                      type="text"
                      name="mobile"
                      id="mobile"
                      handleChange={this.handleChange}
                      value={values.mobile}
                      maxLength={13}
                      invalid={!!errors.mobile}
                      feedBack={errors.mobile}
                      isDisabled={isDisabled}
                    />
                  </Col>
                  {values && values.birthdate && (
                    <Col xs={6} sm={4}>
                      <CustomInput
                        label="Date de naissance"
                        type="date"
                        name="birthdate"
                        id="birthdate"
                        value={values.birthdate}
                        handleChange={this.handleChange}
                        invalid={!!errors.dob}
                        feedBack={errors.dob}
                        isDisabled={isDisabled}
                      />
                    </Col>
                  )}
                  <Col xs={6} sm={4}>
                    <CustomInput
                      label="Siren"
                      type="number"
                      name="siren"
                      id="siren"
                      handleChange={this.handleChange}
                      value={values.siren}
                      maxLength={9}
                      invalid={!!errors.siren}
                      feedBack={errors.siren}
                      isDisabled={isDisabled}
                    />
                  </Col>
                  <Col xs={6} sm={4}>
                    <CustomInput
                      label="TVA"
                      type="text"
                      name="vat"
                      id="vat"
                      handleChange={this.handleChange}
                      value={values.vat}
                      invalid={!!errors.vat}
                      feedBack={errors.vat}
                      isDisabled={isDisabled}
                    />
                  </Col>

                  <Col xs={6} sm={4}>
                    <CustomInput
                      label="URL du site"
                      type="text"
                      name="urlMenu"
                      id="urlMenu"
                      handleChange={this.handleChange}
                      value={values.urlMenu}
                      invalid={!!errors.urlMenu}
                      feedBack={errors.urlMenu}
                      isDisabled={isDisabled}
                    />
                  </Col>
                  <Col xs={6} sm={4}>
                    <AutoCompleteInput
                      label="Adresse"
                      type="text"
                      name="address"
                      id="address"
                      handleChange={this.handleChangeAddress}
                      value={values.address}
                      maxLength={50}
                      invalid={!!errors.address}
                      feedBack={errors.address}
                      isDisabled={isDisabled}
                    />
                  </Col>

                  <Col xs={6} sm={4}>
                    <CustomInput
                      label="activé par"
                      type="input"
                      isDisabled={true}
                      value={values.adminFirstName + " " + values.adminLastName}
                    />
                  </Col>
                  <Col xs={6} sm={4}>
                    <CustomInput
                      label="RIB"
                      type="text"
                      name="rib"
                      id="rib"
                      handleChange={this.handleChange}
                      value={values.rib}
                      invalid={!!errors.rib}
                      feedBack={errors.rib}
                      isDisabled={isDisabled}
                    />
                  </Col>
                  <Col>
                    <CustomInput
                      label="description"
                      type="textarea"
                      name="description"
                      id="description"
                      handleChange={this.handleChange}
                      value={values.description}
                      invalid={!!errors.description}
                      feedBack={errors.description}
                      isDisabled={isDisabled}
                      style={{ fontSize: 16 }}
                    />
                  </Col>
                </Row>
              </ListGroup>
              <h5 className="text-uppercase font-weight-bold mt-3 mb-3">
                {"Gérant" + "  "}
              </h5>
              <Row form>
                <Col xs={6} sm={4}>
                  <CustomInput
                    label="Nom du responsable"
                    type="text"
                    name="ownerFirstName"
                    id="ownerFirstName"
                    handleChange={this.handleChange}
                    value={values.ownerFirstName}
                    maxLength={50}
                    invalid={!!errors.ownerFirstName}
                    feedBack={errors.ownerFirstName}
                    isDisabled={isDisabled}
                  />
                </Col>
                <Col xs={6} sm={4}>
                  <CustomInput
                    label="Prénom du responsable"
                    type="text"
                    name="ownerLastName"
                    id="ownerLastName"
                    handleChange={this.handleChange}
                    value={values.ownerLastName}
                    maxLength={30}
                    invalid={!!errors.ownerLastName}
                    feedBack={errors.ownerLastName}
                    isDisabled={isDisabled}
                  />
                </Col>
                <Col xs={6} sm={4}>
                  <CustomInput
                    label="Téléphone"
                    type="text"
                    name="ownerMobile"
                    id="ownerMobile"
                    handleChange={this.handleChange}
                    value={values.ownerMobile}
                    maxLength={13}
                    invalid={!!errors.ownerMobile}
                    feedBack={errors.ownerMobile}
                    isDisabled={isDisabled}
                  />
                </Col>
                <Col xs={6} sm={4}>
                  <CustomInput
                    label="Email du responsable"
                    type="email"
                    name="ownerEmail"
                    id="ownerEmail"
                    handleChange={this.handleChange}
                    value={values.ownerEmail}
                    maxLength={30}
                    invalid={!!errors.ownerEmail}
                    feedBack={errors.ownerEmail}
                    isDisabled={isDisabled}
                  />
                </Col>
                <Col xs={6} sm={4}>
                  <AutoCompleteInput
                    label="Adresse"
                    type="text"
                    name="addressOwner"
                    id="addressOwner"
                    handleChange={this.handleChangeAddressOwner}
                    value={values.addressOwner}
                    maxLength={50}
                    invalid={!!errors.addressOwner}
                    feedBack={errors.addressOwner}
                    isDisabled={isDisabled}
                  />
                </Col>
                <Col xs={6} sm={4} className="mb-5">
                  <Label className="list-group__label">
                    PIÈCE D'IDENTITÉ RECTO
                  </Label>
                  <div
                    className={
                      isDisabled
                        ? "d-flex justify-content-start"
                        : "d-flex justify-content-between"
                    }>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {this.renderFilePreview(
                        values.nidFront,
                        values.nidFrontToSend
                      )}
                      {errors && errors.nidFront && (
                        <span
                          style={{
                            width: " 100%",
                            marginTop: 5,
                            fontSize: 11,
                            color: "#dc3545",
                          }}>
                          {errors.nidFront}
                        </span>
                      )}
                    </div>
                  </div>
                </Col>
                <Col xs={6} sm={4} className="mb-5">
                  <Label className="list-group__label">
                    PIÈCE D'IDENTITÉ VERSO
                  </Label>
                  <div
                    className={
                      isDisabled
                        ? "d-flex justify-content-start"
                        : "d-flex justify-content-between"
                    }>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {this.renderFilePreview(
                        values.nidBack,
                        values.nidBackToSend
                      )}
                      {errors && errors.nidBack && (
                        <span
                          style={{
                            width: " 100%",
                            marginTop: 5,
                            fontSize: 11,
                            color: "#dc3545",
                          }}>
                          {errors.nidBack}
                        </span>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="mt-4 d-flex flex-row justify-content-center">
                <Button
                  onClick={() => this.props.history.goBack()}
                  className="mr-sm-2"
                  outline
                  disabled={this.state.loading}
                  color="secondary">
                  ANNULER
                </Button>
                <Button
                  onClick={this.submit}
                  className="text-white"
                  disabled={this.state.loading}
                  color="success">
                  ENREGISTRER
                </Button>
              </div>
            </div>
          )}
        </GraphQLResult>
      </Container>
    );
  }
}

DriverStripe.propTypes = {
  driver: PropTypes.object,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

DriverStripe.defaultProps = {
  loading: true,
  error: null,
  isDisabled: false,
};

const QUERY_DRIVER = gql`
  query Driver($id: ID!) {
    driver(id: $id) {
      id
      driverDeleted
      driverDeletedAt
      driverDeletedBy
      nidFront
      nidBack
      ...DriverDetailsUpdate
    }
  }
  ${UserUpdate.fragments.driverDetailsUpdate}
`;
const CREATE_LINKED_ACCOUNT = gql`
  mutation createLinkedAcount($input: LinkedAccountInput!) {
    createLinkedAcount(input: $input)
  }
`;

export default compose(
  graphql(CREATE_LINKED_ACCOUNT, {
    name: "createLinkedAcount",
  }),
  graphql(QUERY_DRIVER, {
    options: ({
      match: {
        params: { id },
      },
    }) => ({
      fetchPolicy: "network-only",
      variables: {
        id,
      },
    }),
    props: ({ data: { loading, error, driver } }) => ({
      error,
      loading,
      driver,
    }),
  })
)(withRouter(DriverStripe));
