import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink, from, split } from "apollo-link";
import { onError } from "apollo-link-error";
import { withClientState } from "apollo-link-state";
import { WebSocketLink } from "apollo-link-ws";
import { createUploadLink } from "apollo-upload-client";
import { getMainDefinition } from "apollo-utilities";
import React from "react";
import { ApolloProvider } from "react-apollo";
import { SubscriptionClient } from "subscriptions-transport-ws";
import resolvers from "./resolvers.local";
import Routes from "./Routes";

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: localStorage.getItem("token") || null
    }
  }));
  return forward(operation);
});
const wsClient = new SubscriptionClient(
  `${process.env.REACT_APP_WS_URL}/subscriptions`,
  {
    reconnect: true,
    connectionParams: {
      authToken: localStorage.getItem("token")
    }
  }
);

wsClient.onError(e => {
  console.log("on WS error");
  console.log({ ...e });
});
wsClient.onConnected(() => console.log("connected to ws"));
wsClient.onDisconnected(() => console.log("disconnected from ws"));
const wsLink = new WebSocketLink(wsClient);

const uploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_URL}/api`
});

const cache = new InMemoryCache({
  dataIdFromObject: o => {
    switch (o.__typename) {
      case "Addon":
        return `${o.id}${
          o.items
            ? o.items.map(item => `${item.id}-${item.price}`).join("-")
            : ""
        }`;
      default:
        return o.id || null;
    }
  }
});

const stateLink = withClientState({
  defaults: {
    isLoggedIn: {
      isLoggedIn: !!localStorage.getItem("token"),
      __typename: "IsLoggedIn"
    }
  },
  resolvers,
  cache
});

const errorLink = onError(
  ({ graphQLErrors, networkError, response, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations
          )}, Path: ${path}`
        )
      );
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }

    if (operation.operationName === "IgnoreErrorsQuery") {
      response.errors = null;
    }
  }
);

const client = new ApolloClient({
  link: split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === "OperationDefinition" && operation === "subscription";
    },
    wsLink,
    from([errorLink, authMiddleware, stateLink, uploadLink])
  ),
  resolvers,
  cache
});

class App extends React.PureComponent {
  render() {
    return (
      <ApolloProvider client={client}>
        <Routes />
      </ApolloProvider>
    );
  }
}

export default App;
