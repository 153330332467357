import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Modal, ModalBody } from "reactstrap";

class CropImgModal extends PureComponent {
  state = {
    crop: {
      unit: "px",
      width: 200,
      height: 200,
      aspect: 1
    }
  };
  onImageLoaded = image => {
    const width = image.width / 2 - 100 > 0 ? image.width / 2 - 100 : 0;
    const height = image.height / 2 - 100 > 0 ? image.height / 2 - 100 : 0;
    this.setState({
      crop: {
        unit: "px",
        aspect: 1,
        width: 200,
        height: 200,
        x: width,
        y: height
      }
    });
    this.imageRef = image;
    return false;
  };
  onCropChange = crop => {
    this.setState({ crop });
  };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) return reject("zzz");
        blob.name = fileName;
        const date = new Date();
        const file = new File([blob], date.toISOString(), {
          type: "image/png",
          lastModified: date
        });
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve({ file, fileUrl: this.fileUrl });
      }, "image/png");
    });
  }
  onConfirm = async () => {
    const { crop } = this.state;
    const { onConfirm, onCancel } = this.props;
    if (this.imageRef && crop.width && crop.height) {
      try {
        const { file, fileUrl } = await this.getCroppedImg(
          this.imageRef,
          crop,
          "newFile.png"
        );
        onConfirm(file, fileUrl);
      } catch (e) {
        onCancel();
      }
    } else {
      onCancel();
    }
  };

  render() {
    const { crop } = this.state;
    const { src, onCancel, isOpen } = this.props;

    return (
      <Modal
        size="md"
        centered
        onClosed={onCancel}
        toggle={onCancel}
        isOpen={isOpen}
        className="modal-transparent-fit"
      >
        <ModalBody className="p-0 text-center">
          {src && (
            <>
              <ReactCrop
                src={src}
                crop={crop}
                crossorigin={"anonymous"}
                minHeight={150}
                minWidth={150}
                maxWidth={300}
                maxHeight={300}
                circularCrop
                onImageLoaded={this.onImageLoaded}
                onChange={this.onCropChange}
              />
              <div className="crop-btns-container">
                <a className="checkbox-btn checkbox-btn-red" onClick={onCancel}>
                  <i className="fa fa-times" />
                </a>
                <a className="checkbox-btn" onClick={this.onConfirm}>
                  <i className="fa fa-check" />
                </a>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

CropImgModal.propTypes = {
  src: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};
export default CropImgModal;
