import gql from "graphql-tag";

export const SET_TOKEN = gql`
  mutation SetToken($token: String = null) {
    setToken(token: $token) @client
  }
`;

export const UPDATE_KITCHEN_CATEGORIES = gql`
  mutation UpdateKitchenCategory(
    $kitchenCategory: KitchenCategory!
    $isNew: Boolean!
  ) {
    updateKitchenCategory(kitchenCategory: $kitchenCategory, isNew: $isNew)
      @client
  }
`;

export const UPDATE_MENU_CATEGORIES = gql`
  mutation UpdateMenuCatgeories(
    $menuCategory: MenuCategory!
    $isNew: Boolean!
  ) {
    updateMenuCategories(menuCategory: $menuCategory, isNew: $isNew) @client
  }
`;

export const UPDATE_STORE_CATEGORIES = gql`
  mutation UpdateStoreCatgeories($storeType: storeType!, $isNew: Boolean!) {
    updateStoreCategories(storeType: $storeType, isNew: $isNew) @client
  }
`;

export const UPDATE_SUB_CATEGORIES = gql`
  mutation UpdateSubCatgeories($subCategory: subCategory!, $isNew: Boolean!) {
    updateSubCatgeories(subCategory: $subCategory, isNew: $isNew) @client
  }
`;

export const DECREMENT_REQUEST_NUMBER = gql`
  mutation DecrementRequestNumber($type: String) {
    decrementRequestNumber(type: $type) @client
  }
`;

export const INCREMENT_MENU_REQUETS_LENGTH = gql`
  mutation IncrementMenuRequestsLength($inc: Int!) {
    inscrementMenuRequestsLength(inc: $inc) @client
  }
`;

//notifications

export const UPDATE_NOTIFICATIONS_LENGTH = gql`
  mutation UpdateNotificationsLength($length: Int!, $looked: Boolean!) {
    updateNotificationsLength(length: $length, looked: $looked) @client
  }
`;
export const INCREMENT_NOTIFICATION_LENGTH = gql`
  mutation IncrementNotificationsLength($looked: Boolean!) {
    incrementNotificationsLength(looked: $looked) @client
  }
`;
