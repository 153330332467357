import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";

class CustomInput extends Component {
  render() {
    const {
      label,
      type,
      name,
      id,
      value,
      children,
      handleChange,
      feedBack,
      isDisabled,
      ...rest
    } = this.props;

    return (
      <FormGroup>
        <Label className="list-group__label" for={id}>
          {label}
        </Label>
        <Input
          id={id}
          name={name}
          type={type}
          value={value}
          onChange={handleChange}
          disabled={isDisabled}
          {...rest}
        >
          {children && children}
        </Input>
        <FormFeedback>{feedBack}</FormFeedback>
      </FormGroup>
    );
  }
}

export default CustomInput;

CustomInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.any.isRequired,
  handleChange: PropTypes.func.isRequired,
  children: PropTypes.node,
  rest: PropTypes.object,
  feedBack: PropTypes.string,
  isDisabled: PropTypes.bool
};

CustomInput.defaultProps = {
  isDisabled: true
};
