import PropTypes from "prop-types";
import React, { Component } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import Alert from "../../../utils/Alert";
import { graphql } from "react-apollo";
import { ME } from "../../../queries";
import { userTypes } from "../../../utils/constants";
const searchOptions = {
  componentRestrictions: { country: "fr" },
};

class AutoCompleteInput extends Component {
  static propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.any.isRequired,
    handleChange: PropTypes.func,
    children: PropTypes.node,
    rest: PropTypes.object,
    feedBack: PropTypes.string,
    isDisabled: PropTypes.bool,
    maxLength: PropTypes.number,
    invalid: PropTypes.bool,
  };

  static defaultProps = {
    isDisabled: true,
  };

  state = {
    address: this.props.value,
    error: "",
  };

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((result) => {
        return getLatLng(result[0]).then((data) => {
          return { data, result };
        });
      })
      .then(({ data, result }) => {
        if (
          this.props.me &&
          this.props.me.role &&
          this.props.me.role === userTypes.MINI_ADMIN
        ) {
          let city = result[0].address_components[2].long_name;
          let zipCode = result[0].address_components[6].long_name;
          let country = result[0].address_components[5].long_name;
          if (
            this.props.me &&
            this.props.me.zone &&
            this.props.me.zone.cities &&
            this.props.me.zone.cities.indexof(city)
          ) {
            this.handleChange(address);
            this.props.handleChange({
              address,
              lat: data.lat,
              lng: data.lng,
              city,
              country,
              zipCode: parseInt(zipCode),
            });
          } else alert("L'adresse séléctionné est hors de votre zone");
        } else {
          this.handleChange(address);
          let city = result[0].address_components[2].long_name;
          let zipCode = result[0].address_components[6].long_name;
          let country = result[0].address_components[5].long_name;
          this.props.handleChange({
            address,
            lat: data.lat,
            lng: data.lng,
            city,
            country,
            zipCode: parseInt(zipCode),
          });
        }
      })
      .catch((error) => Alert.error(error));
  };

  render() {
    const { address } = this.state;
    const {
      label,
      type,
      name,
      id,
      maxLength,
      invalid,
      feedBack,
      isDisabled,
    } = this.props;
    return (
      <PlacesAutocomplete
        value={address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        shouldFetchSuggestions={
          this.state.address && this.state.address.length > 2
        }
        searchOptions={searchOptions}
        debounce={1000}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <FormGroup>
              <Label className="list-group__label" for={id}>
                {label}
              </Label>
              <Input
                {...getInputProps({
                  type,
                  name,
                  id,
                  disabled: isDisabled,
                  maxLength,
                  invalid,
                })}
              />
              <FormFeedback>{feedBack}</FormFeedback>
            </FormGroup>
            <div className=".autocomplete-dropdown-container">
              {loading && (
                <div className="d-flex align-items-center">
                  <i className="fa fa-spinner fa-spin mr-sm-2" />
                  <span>Recherche en cours</span>
                </div>
              )}
              {suggestions.map((suggestion, index) => {
                const className = suggestion.active
                  ? "suggestion-item-active"
                  : "suggestion-item";
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };

                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    key={index.toString()}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default graphql(ME, {
  props: ({ data: { loading, error, me } }) => ({
    loading,
    error,
    me,
  }),
})(AutoCompleteInput);
