import React from "react";
import PropTypes from "prop-types";

class Icon extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    id: PropTypes.string.isRequired
  };

  static defaultProps = {
    className: ""
  };

  render() {
    const { id, className } = this.props;
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={`icon ${className}`.trim()}
      >
        {!process.env.NODE_ENV || process.env.NODE_ENV === "development" ? (
          <use xlinkHref={`/icons/sprite.svg#${id}`} />
        ) : (
          <use xlinkHref={`/static/icons/sprite.svg#${id}`} />
        )}
      </svg>
    );
  }
}

export default Icon;
