import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Collapse } from "reactstrap";

export default class collapseItemDetails extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    addons: PropTypes.array,
    note: PropTypes.string,
    price: PropTypes.number,
    quantity: PropTypes.number,
  };

  static defaultProps = {
    name: null,
    addons: [],
    note: null,
    price: null,
    quantity: 1,
    total: null,
  };
  state = {
    collapse: false,
  };
  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };
  render() {
    const { collapse } = this.state;
    const { name, addons, note, price, quantity, total } = this.props;

    return (
      <React.Fragment>
        <button className="collapse__button d-flex " onClick={this.toggle}>
          <span>{name}</span>
          {addons[0] && (
            <i
              className={
                collapse
                  ? "rotate90  collapse__icon fa fa-angle-right ml-2"
                  : "fa fa-angle-right ml-2"
              }
              style={{ fontSize: "20px" }}
            />
          )}
          {total / quantity === price ? (
            <span className="price-quantuty">{`${price.toFixed(
              2
            )}€ x ${quantity}`}</span>
          ) : (
            <div>
              <span className="price-quantuty">{`${(total / quantity).toFixed(
                2
              )}`}</span>
              <span
                className="price-quantuty"
                style={{
                  textDecorationLine: "line-through",
                  textDecorationStyle: "solid",
                  marginTop: -3,
                  fontSize: 11,
                  marginLeft: 4,
                  position: "absolute",
                }}
              >{`${price.toFixed(2)}`}</span>
              <span
                className="price-quantuty"
                style={{ position: "absolute", marginLeft: 40 }}
              >{`€ x ${quantity}`}</span>
            </div>
          )}
        </button>
        {addons[0] && (
          <Collapse tag="ul" isOpen={collapse} className="mt-2">
            {addons.map(({ name, items }, index) => {
              return (
                <p key={index} className="ml-3 ">
                  <span>-{name}</span>
                  <span>
                    {items.map(({ name, price }, index) => (
                      <span className="ml-3" key={index}>
                        {name}
                        <span className="price-quantuty">{`${price.toFixed(
                          2
                        )}€ `}</span>
                      </span>
                    ))}
                  </span>
                </p>
              );
            })}
            {note && <div className="comment">Commentaire : {note}</div>}
          </Collapse>
        )}
      </React.Fragment>
    );
  }
}
