import React from "react";
import { ListGroupItem } from "reactstrap";
import PropTypes from "prop-types";

class ItemDetails extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    valueClassName: PropTypes.string,
    label: PropTypes.any,
    value: PropTypes.any,
    children: PropTypes.any,
  };

  static defaultProps = {
    className: "",
    labelClassName: "",
    valueClassName: "",
    label: null,
    children: null,
    value: null,
    bold: false,
  };

  render() {
    const {
      className,
      labelClassName,
      valueClassName,
      label,
      children,
      value,
      bold,
    } = this.props;
    return (
      <ListGroupItem
        tag="div"
        className={`list-group__item ${className}`.trim()}>
        <div className={`list-group__label ${labelClassName}`.trim()}>
          {label}
        </div>
        {children ? (
          <div className="list-group__value">{children}</div>
        ) : (
          <span
            className={`list-group__value ${valueClassName}`.trim()}
            style={bold ? { fontWeight: 800, color: "#37a848" } : {}}>
            {value}
          </span>
        )}
      </ListGroupItem>
    );
  }
}

export default ItemDetails;
