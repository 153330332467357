import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import Button from "./Button";

class ConfirmationModal extends React.PureComponent {
  static propTypes = {
    size: PropTypes.string,
    isVisible: PropTypes.bool,
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    children: PropTypes.any,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    btnConfirmText: PropTypes.string,
    btnCancelText: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool
  };

  static defaultProps = {
    size: "md",
    loading: false,
    btnConfirmText: "Confirmer",
    btnCancelText: "Annuler",
    disabled: false,
    children: null,
    text: null
  };

  render() {
    const {
      size,
      isVisible,
      title,
      text,
      children,
      loading,
      disabled,
      btnCancelText,
      btnConfirmText,
      onConfirm,
      onCancel
    } = this.props;
    return (
      <Modal
        size={size}
        isOpen={isVisible}
        toggle={loading ? null : onCancel}
        centered
        fade={false}
      >
        <ModalHeader className="modal__header text-center">{title}</ModalHeader>
        <ModalBody className="modal__body">
          {text}
          {children}
        </ModalBody>
        <ModalFooter className="modal__footer">
          <Button
            block
            size={size}
            color="secondary"
            onClick={onCancel}
            text={btnCancelText}
          />
          <div />
          <Button
            block
            size={size}
            color="success"
            onClick={onConfirm}
            loading={loading}
            text={btnConfirmText}
            disabled={disabled}
          />
        </ModalFooter>
      </Modal>
    );
  }
}

export default ConfirmationModal;
