export const PROJECT_NAME = "Halalkoom";
const HOME = "Tableau de bord";
const PROFILE = "Modification de profil";
const ADMINS = "Gestion des administrateurs";
const MINI_ADMINS = "Gestion des gestionnaires";

// const REQUESTS = "Gestion des demandes d'inscription";

const RESTAURANT_REQUESTS =
  "Gestion des demandes d'inscription des restaurants";
const RESTAURANT_REQUEST_DETAILS = "Inscription restaurant";
const RESTAURANT_REQUEST_DETAILS_BREAD_CRUMB = "Demande d'inscription";
const DRIVERS_REQUESTS = "Gestion des demandes d'inscription des livreurs";

const MENU_REQUESTS = "Gestion des demandes d'approbation";
const CODE_PROMOS = "Gestion des promotions";
const STORES = "Gestion des restaurants ";
const CUSTOMERS = "Gestion des clients";
const FRANCHISES = "Gestion des franchises";
const DRIVER = "Gestion des livreurs";
const STRIPE_DRIVER = "Créer un compte stripe pour un livrerur";
const STRIPE_ADMIN = "Créer un compte stripe pour un admin";
const STRIPE_STORE = "Créer un compte stripe pour un restaurant";
const LOGIN = "Connexion";
const TRANSACTION = "Paiement";
const TRANSACTION_SUB_TITLE = "Historique";
const ORDERS = "Gestion des commandes";
const FAKE_ORDERS = "Liste de tentatives de commandes";
const CREATE_ADMIN = "Créer un nouveau administrateur";
const CREATE_MINI_ADMIN = "Créer un nouveau gestionnaire";
const ADMIN_DETAILS = "Détails administrateur";
const MENU_CATEGORIES = "Gestion des catégories de menu";
const SUB_CATEGORIES = "Gestion des sous catégories de menu";
const MENU_TYPES = "Gestion des types de produits";
const CREATE_PROMOTION = "Créer une promotion";
const UPDATE_PROMOTION = "Modifier une promotion";
const CREATE_MENU_TYPE = "Créer un type de produit";
const UPDATE_MENU_TYPE = "Modifier un produit";
const CHANGE_PASSWORD = "Changer le mot de passe";
const CHANGE_STORES_PASSWORD = "Changer le mot de passe Restaurants";

const HISTORIC = "Historique de modification des menus";
const MENU_DETAILS = "Détails du menu";
const RESCUED_ORDERS = "Sauvetage";
const FRANCHISE = "Franchises";

const Notifications = "Notifications";
const Settings = "Paramètres";
const PAYOUTS = "Virements";
const PAYOUT_DETAIL = "Détails";
const REPORT = "Génération rapport";
const TRANSFER_DETAIL = "Détails transaction";
const STREAMERS = "Gestion des diffuseurs";
const CREATE_STREAMER = "Créer un diffuseur";
const STREAMER_DETAIL = "Détails diffuseur";

const KITCHEN_CATEGORIES = "Gestion des spécialités";
const ZONES = "Zones géographiques";
const CREATE_ZONE = "Créer une zone géographique";
const ZONE_DETAIL = "Détail d'une zone géographique";

export const titles = {
  HISTORIC,
  LOGIN,
  HOME,
  PROFILE,
  CHANGE_PASSWORD,
  CHANGE_STORES_PASSWORD,
  ADMINS,
  MINI_ADMINS,
  ADMIN_DETAILS,
  CREATE_ADMIN,
  CREATE_MINI_ADMIN,
  STRIPE_DRIVER,
  STRIPE_ADMIN,
  STRIPE_STORE,
  FAKE_ORDERS,
  RESTAURANT_REQUESTS,
  RESTAURANT_REQUEST_DETAILS,
  RESTAURANT_REQUEST_DETAILS_BREAD_CRUMB,
  DRIVERS_REQUESTS,
  MENU_REQUESTS,
  CODE_PROMOS,
  CUSTOMERS,
  FRANCHISES,
  STORES,
  DRIVER,
  TRANSACTION,
  TRANSACTION_SUB_TITLE,
  KITCHEN_CATEGORIES,
  MENU_CATEGORIES,
  MENU_TYPES,
  ORDERS,
  SUB_CATEGORIES,
  CREATE_PROMOTION,
  UPDATE_PROMOTION,
  CREATE_MENU_TYPE,
  UPDATE_MENU_TYPE,
  MENU_DETAILS,
  RESCUED_ORDERS,
  Notifications,
  Settings,
  PAYOUTS,
  PAYOUT_DETAIL,
  TRANSFER_DETAIL,
  REPORT,
  STREAMERS,
  CREATE_STREAMER,
  STREAMER_DETAIL,
  FRANCHISE,
  ZONES,
  CREATE_ZONE,
  ZONE_DETAIL,
};

// DELIVERY TYPES
const TO_CAR = "TO_CAR";
const TO_HOME = "TO_HOME";
export const deliveryTypes = [TO_CAR, TO_HOME];
export const deliveryModes = ["EXPRESS", "DRIVE"];
export const vehiculesTypes = {
  BICYCLE: "vélo",
};

export const requestStatus = {
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
};

export const menuStatusDetails = {
  ACTIVE: {
    label: "Activé",
    className: "text-success",
  },
  INACTIVE: {
    label: "Désactivé",
    className: "text-danger",
  },
};

export const requestStatusDetails = {
  IN_PROGRESS: {
    label: "EN COURS",
    className: "text-warning",
  },
  ACCEPTED: {
    label: "ACCEPTÉE",
    className: "text-success",
  },
  REJECTED: {
    label: "REJETÉE",
    className: "text-danger",
  },
};

export const routeToTitle = {
  "": HOME,
  accueil: HOME,
  paramètres: Settings,
  administrateurs: ADMINS,
  franchises: FRANCHISE,
  zones: ZONES,
  //   demandes: REQUESTS,
  //  inscription: REQUESTS,
  "inscription-restaurants": RESTAURANT_REQUESTS,
  inscription: RESTAURANT_REQUEST_DETAILS,

  "approbation-carte": MENU_REQUESTS,
  "inscription-livreurs": DRIVERS_REQUESTS,
  clients: CUSTOMERS,
  "code-promos": CODE_PROMOS,
  diffuseurs: STREAMERS,
  restaurants: STORES,
  livreurs: DRIVER,
  paiements: TRANSACTION,
  spécialité: KITCHEN_CATEGORIES,
  menus: MENU_CATEGORIES,
  "sous-sous-categories": MENU_TYPES,
  "sous-categories": SUB_CATEGORIES,
  commandes: ORDERS,
  sauvetage: RESCUED_ORDERS,

  historique: HISTORIC,
};

//
export const DELIVERED = "DELIVERED";
export const CURRENT = "CURRENT";
export const OUTGOING = "OUTGOING";
export const ACCEPTED_BY_STORE = "ACCEPTED_BY_STORE";
export const ACCEPTED = "ACCEPTED";

//REJECTED-ORDER
export const REJECTED_BY_CUSTOMER = "REJECTED_BY_CUSTOMER";
export const REJECTED_BY_DRIVER = "REJECTED_BY_DRIVER";
export const REJECTED_BY_STORE = "REJECTED_BY_STORE";
export const REJECTED_BY_ADMIN = "REJECTED_BY_ADMIN";
export const REJECTED_BY_SYSTEM = "REJECTED_BY_SYSTEM";
export const SWAPPED = "SWAPPED";
export const SALVAGE = "SALVAGE";
export const SALVAGED = "SALVAGED";
export const rejectedStatus = [
  REJECTED_BY_CUSTOMER,
  REJECTED_BY_DRIVER,
  REJECTED_BY_STORE,
  REJECTED_BY_SYSTEM,
  REJECTED_BY_ADMIN,
];

export const orderStatus = {
  OUTGOING,
  DELIVERED,
  REJECTED_BY_ADMIN,
  REJECTED_BY_CUSTOMER,
  REJECTED_BY_DRIVER,
  REJECTED_BY_STORE,
  REJECTED_BY_SYSTEM,
  SWAPPED,
};

export const statusTitle = {
  CURRENT: "CURRENT",
  READY: "READY",
  REJECTED: "REJECTED",
  IN_PROGRESS: "IN_PROGRESS",
};

export const limits = [10, 20, 40, 60, 80, 100];
export const requestsFilter = ["Toutes", "Avec Contrat", "Sans contrat"];

// ALERT

export const ALERT_WARNING = {
  type: "warning",
  icon: "fa fa-exclamation-triangle",
};

export const ALERT_ERROR = {
  type: "error",
  icon: "fa fa-exclamation-circle",
};

export const ALERT_INFO = {
  type: "info",
  icon: "fa fa-info",
};

export const ALERT_SUCCESS = {
  type: "success",
  icon: "fa fa-check",
};

export const ALERT_DEFAULT_CONFIG = {
  manyInstances: false,
  disableAutoClose: false,
  autoClose: 2500,
};

// STATUS
export const INACTIVE = "INACTIVE";
export const ACTIVE = "ACTIVE";
export const IN_PROGRESS = "IN_PROGRESS";
export const COMMANDES = "COMMANDES";
export const PRODUITS = "PRODUITS";

export const statusOptions = [
  { label: "Actif", value: ACTIVE },
  { label: "Inactif", value: INACTIVE },
];
export const minimumAmmounts = [
  { label: "Sans montant minimum", value: false },
  { label: "Avec montant minimum", value: true },
];

export const commandesOrProducts = [
  { label: "Commande", value: "ORDER" },
  { label: "Secteur d’activité", value: "STORE_TYPE" },
];

export const categoriesOfProducts = [
  { label: "Categorie 1", value: "Categorie 1" },
  { label: "Categorie 2", value: "Categorie 2" },
  { label: "Categorie 3", value: "Categorie 3" },
];

export const yesNoOptions = [
  { label: "Sans", value: false },
  { label: "Avec", value: true },
];

// REGEX
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const NAME_REGEX = /^[a-zA-Z0-9 '-]{2,50}$/;
export const REAL_NAME_REGEX = /^[a-zA-Z ]{2,50}$/;
export const REGEX_MOBILE = /*/^(?:\+216)[0-9]{8}$/; */ /^(?:\+33)[0-9]{9,10}$/;
export const MOBILE_REGEX_INCLUSIVE = /^(:?(?:\+|00)33|0)[1-9]{1}[0-9]{8}$/;
export const REGEX_MOBILE_NO_PREFIX = /*/^(?:\+216)[0-9]{8}$/; */ /^[0-9]{9}$/;
export const PREPARATION_TIME = /^[0-9]{2}$/;

export const VAT_REGEX = /^(FR)[0-9]{11}$/;
export const SIRET_REGEX = /^[0-9]{14}$/;
export const SIREN_REGEX = /^[0-9]{9}$/;

export const RIB_REGEX = /^[0-9]{11}$/;

// DATE FORMAT
export const FRENCH_DATE_FORMAT = "DD/MM/YYYY";
export const FRENCH_DATE_TIME_FORMAT = `${FRENCH_DATE_FORMAT} [à] HH:mm`;
export const INPUT_DATE_FORMAT = "YYYY-MM-DD";
export const createdForConstants = [
  {
    label: "Tous",
    value: "ALL",
  },
  {
    label: "Diffuseurs",
    value: "STREAMER",
  },
  {
    label: "Clients",
    value: "CUSTOMER",
  },
];
export const DriverConstants = [
  {
    label: "Tous",
    value: null,
  },
  {
    label: "Livreur(s) détécté(s)",
    value: false,
  },
  {
    label: "Pas de livreur(s) détécté(s)",
    value: true,
  },
];
export const StripeConstants = [
  {
    label: "Tous",
    value: null,
  },
  {
    label: "Restaurant avec Stripe",
    value: false,
  },
  {
    label: "Restaurant sans Stripe",
    value: true,
  },
];
export const storeCategories = {
  BUSINESS: "Restaurant",
  AT_HOME: "Artisan",
};
export const storeCategoriesSelect = [
  {
    label: "Tous",
    value: "none",
  },
  {
    label: "Restaurant",
    value: "BUSINESS",
  },
  {
    label: "Artisan",
    value: "AT_HOME",
  },
];

export const actorStatusSelect = [
  {
    label: "Tous",
    value: "none",
  },
  {
    label: "Actif",
    value: ACTIVE,
  },
  {
    label: "Inactif",
    value: INACTIVE,
  },
];

export const stripeFilterSelect = [
  {
    label: "Tous",
    value: "none",
  },
  {
    label: "Avec",
    value: "YES",
  },
  {
    label: "Sans",
    value: "NO",
  },
];
export const RATING_FIELDS = `
  total
  partial
  coef
`;

export const homeTitles = {
  CLIENT: "Total des clients actifs",
  COMMANDE: "Total des commandes livrées",
  RESTURANT: "Total des restaurants actifs",
  DRIVER: "Total des livreurs actifs",
};

export const cardHeaderTitles = {
  OUTGOING_DELEVERING: "LIVRAISON EN COURS",
  SALVAGE_DELIVRING: "LIVRAISON À SAUVER",
  REJECTED_COMMAND: "COMMANDES ANNULÉES",
  COMMAND_WITH_CODE: "COMMANDES AVEC CODES ERRONÉS",
  MENUS: "TOP 10 DES COMMANDES",
  NEWACTORS: "Nouvelles inscriptions",
};

export const LIST_TITLE = "Aperçu des statistiques";
export const zones = [
  {
    name: "Zone A",
    value: 12,
  },
  {
    name: "Zone B",
    value: 20,
  },
];

export const services = {
  DELIVERY: "LIVRAISON",
  CARRY_OUT: "A EMPORTER",
  ALL: "LIVRAISON / À EMPORTER",
};

export const DELIVERY = "DELIVERY";
export const CARRY_OUT = "CARRY_OUT";
export const ALL = "ALL";

export const dietetics = {
  ORGANIC: "BIO",
  VEGAN: "VEGAN",
  VEGETARIAN: "VEGETARIAN",
  GLUTEN_FREE: "SANS GLUTEN",
};

export const PREFIX = "HLKM";
export const PERCENT = "PERCENT";
export const AMOUNT = "AMOUNT";
export const codeTypes = {
  AMOUNT: "Montant",
  PERCENT: "Pourcentage",
};

export const frenchCodeTypes = {
  Montant: "AMOUNT",
  Percentage: "PERCENT",
};

export const SWITCH_HEIGHT = 20;
export const SWITCH_WIDTH = 45;

//DAY
export const days = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];
export const SEANCE = `firstSeance {
  start
  end
}
secondSeance{
  start
  end
}
thirdSeance{
  start
  end
}`;

//storerequests
export const PENDING_REQUEST = "WAITING";
export const IN_PROGRESS_REQUEST = "IN_PROGRESS";
export const ACCEPTED_REQUEST = "ACCEPTED";
export const REJECTED_REQUEST = "REJECTED";

export const storeRequests = {
  PENDING_REQUEST,
  IN_PROGRESS_REQUEST,
  ACCEPTED_REQUEST,
  REJECTED_REQUEST,
};

//driver requests
export const driverRequests = {
  PENDING_REQUEST,
  IN_PROGRESS_REQUEST,
  ACCEPTED_REQUEST,
  REJECTED_REQUEST,
};
// user update request types

export const userTypes = {
  DRIVER: "DRIVER",
  CUSTOMER: "CUSTOMER",
  MANAGER: "MANAGER",
  ADMIN: "ADMIN",
  MINI_ADMIN: "MINI_ADMIN",
  SUPER_ADMIN: "SUPER_ADMIN",
};

//request type

export const DRIVER_REQUEST = "DRIVER_REQUEST";

export const menuTypeOptions = [
  { label: "Spécialités", value: "MENUS" },
  { label: "Catégories de menu", value: "CATEGORIES" },
  { label: "Sous catégories de menu", value: "SUB_CATEGORIES" },
  { label: "Produits", value: "CATEGORY_TYPE" },
];

export const orderStatusOptions = [
  { label: "Livrées", value: DELIVERED },
  { label: "Annulées par le Restaurant", value: REJECTED_BY_STORE },
  { label: "Annulées par le Client", value: REJECTED_BY_CUSTOMER },
  { label: "Annulées par l'Administrateur", value: REJECTED_BY_ADMIN },
];
