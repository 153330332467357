import PropTypes from "prop-types";
import React, { useState } from "react";
import CropImgModal from "./CropImgModal";
import Icon from "./Icon";

function ProfilePhoto({ src, isDisabled, handlePhotoCrop }) {
  const [hovered, setHovered] = useState(false);
  const [showCrop, setShowCrop] = useState(false);

  const toggleHovered = hoverState => () => setHovered(hoverState);
  const toggleShowCrop = showCropState => () => setShowCrop(showCropState);
  function onShowCrop() {
    setHovered(false);
    setShowCrop(true);
  }
  function onConfirm(file, url) {
    handlePhotoCrop(file, url);
    setShowCrop(false);
  }

  return (
    <div
      className={
        isDisabled
          ? "profile-image-container-no-hover"
          : "profile-image-container"
      }
      onMouseEnter={isDisabled ? null : toggleHovered(true)}
      onMouseLeave={toggleHovered(false)}
    >
      <img src={src} alt="Profile Picture" />
      {hovered && (
        <a className={"crop-btn"} onClick={onShowCrop}>
          <Icon id="crop" className="crop-btn__icon" />
        </a>
      )}
      <CropImgModal
        isOpen={showCrop}
        src={src}
        onCancel={toggleShowCrop(false)}
        onConfirm={onConfirm}
      />
    </div>
  );
}

ProfilePhoto.propTypes = {
  src: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  handlePhotoCrop: PropTypes.func.isRequired
};

export default ProfilePhoto;
