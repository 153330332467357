import React from "react";
import PropTypes from "prop-types";

import BreadCrumbs from "./BreadCrumbs";

class Container extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    breadCrumbs: PropTypes.array
  };

  static defaultProps = {
    breadCrumbs: null,
    className: "",
    children: null
  };

  render() {
    const { className, children, breadCrumbs } = this.props;
    return (
      <div className={`container-fluid ${className}`.trim()}>
        {breadCrumbs && <BreadCrumbs items={breadCrumbs} />}
        {children}
      </div>
    );
  }
}

export default Container;
