import locations from "countries-cities";
import "moment/locale/fr";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Avatar from "react-avatar";
import {
  Badge,
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  ListGroup,
  Row,
  FormFeedback,
} from "reactstrap";
import {
  ACTIVE,
  INACTIVE,
  userTypes,
  FRENCH_DATE_FORMAT,
} from "../../utils/constants";
import ProfilePhoto from "../common/ProfilePhoto";
import AutoCompleteInput from "../request/newRequestDetails/AutoCompleteInput";
import CustomInput from "../request/newRequestDetails/CustomInput";
import { Link } from "react-router-dom";
import moment from "moment";
import ZonesSelect from "../common/ZonesSelect";

const defaultPdfImage = "/images/pdf.png";

class UserUpdateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevprops) {
    if (prevprops.values.country !== this.props.values.country) {
      this.setState({
        cities: [...locations.getCities(this.props.values.country)],
      });
    }
  }

  triggerFileUpload = (name) => () => {
    switch (name) {
      case "photoUrl":
        this.photoUrl.click();
        break;
      case "kbis":
        this.kbis.click();
        break;
      /*   case "criminalRecord":
        this.criminalRecord.click();
        break; */
      case "nidFront":
        this.nidFront.click();
        break;
      case "nidBack":
        this.nidBack.click();
      case "contract":
        this.contract.click();
        break;
      default:
        return null;
    }
  };

  setRef = (ref) => {
    if (ref) this[ref.name] = ref;
  };

  handleFileUpload = (e) => {
    e.persist();
    const { name } = e.target;
    if (e.target.files && e.target.files[0]) {
      console.log(e.target.files[0]);
      this.props.setFieldValue([name], URL.createObjectURL(e.target.files[0]));
      this.props.setFieldValue([`${name}ToSend`], e.target.files[0]);
      this.props.setFieldValue([`${name}Changed`], true);
    }
  };

  handlePhotoCrop = (file, fileUrl) => {
    if (file && fileUrl) {
      this.props.setFieldValue("photoUrl", fileUrl);
      this.props.setFieldValue("photoUrlToSend", file);
      this.props.setFieldValue("photoUrlChanged", true);
    }
  };

  handleChangeAddress = ({ address, lat, lng }) => {
    this.props.setFieldValue("address", address);
    this.props.setFieldValue("location", { lat, lng });
  };
  handleChangeBirthdate = (e) => {
    e.preventDefault();
    const { name } = e.target;
    this.props.setFieldValue(
      [name],
      moment(e.target.value, "YYYY-MM-DD").valueOf()
    );
    this.props.setFieldValue("dob", {
      year: e.target.value.split("-")[0],
      month: e.target.value.split("-")[1],
      day: e.target.value.split("-")[2],
    });
  };
  toggleInput = () => {
    this.props.toggleInput();
    if (!this.props.isDisabled) this.props.handleReset();
  };

  renderFilePreview = (path, toSend) => {
    if (!path || path.length < 1) return null;
    if (
      path.endsWith(".pdf") ||
      (toSend && toSend.type === "application/pdf")
    ) {
      return (
        <a href={path} target="_blank" rel="noopener noreferrer">
          <img
            src={defaultPdfImage}
            style={{
              height: "120px",
            }}
          />
        </a>
      );
    } else
      return (
        <a href={path} target="_blank" rel="noopener noreferrer">
          <img
            src={path}
            style={{
              width: "120px",
              height: "120px",
              backgroundSize: "cover",
            }}
          />
        </a>
      );
  };

  render() {
    const {
      title,
      handleChange,
      values,
      errors,
      isDisabled,
      userType,
      openDeleteModal,
      hasStripe,
      openStripeForm,
      stripeAccountId,
    } = this.props;
    return (
      <>
        <Form>
          <Button className="float-right ml-2 small" onClick={this.toggleInput}>
            <i className="fa fa-pencil" />
          </Button>
          <Button
            className="float-right btn-danger ml-2 small"
            onClick={openDeleteModal}
          >
            <i className="fa fa-trash" />
          </Button>{" "}
          {userType === userTypes.ADMIN ? (
            <div />
          ) : !hasStripe && userType !== userTypes.CUSTOMER ? (
            <Button
              className="float-right btn-primary ml-2 small"
              style={{ marginRight: 10, padding: "0px 2px" }}
              onClick={openStripeForm}
            >
              <i className="fa fa-cc-stripe" style={{ fontSize: 28 }} />
            </Button>
          ) : stripeAccountId ? (
            <span
              style={{
                position: "absolute",
                right: 100,
                top: 16,
                backgroundColor: "#b5b5b5",
                padding: 5,
                borderRadius: 3,
              }}
            >
              Stripe ID: {stripeAccountId}
            </span>
          ) : null}
          <FormGroup>
            <div className="d-flex  justify-content-center float-center">
              {values.photoUrl ? (
                <ProfilePhoto
                  src={values.photoUrl}
                  isDisabled={isDisabled}
                  handlePhotoCrop={this.handlePhotoCrop}
                />
              ) : (
                <Avatar
                  className="float-center"
                  size="200"
                  round={true}
                  textSizeRatio="1.75"
                  name={values.firstName + " " + values.lastName}
                />
              )}
              {!isDisabled && (
                <Button
                  className={`rounded-circle  upload-btn small ${
                    isDisabled ? "btn-enabled" : ""
                  }`}
                  onClick={this.triggerFileUpload("photoUrl")}
                  disabled={isDisabled}
                >
                  <i className="fa fa-camera" />
                </Button>
              )}
              <input
                type="file"
                name="photoUrl"
                id="photoUrl"
                ref={this.setRef}
                accept="image/*"
                hidden="hidden"
                onChange={this.handleFileUpload}
              />
            </div>
          </FormGroup>
          <h5 className="text-uppercase font-weight-bold mt-3 mb-3">
            {title}{" "}
            {values.code && <Badge color="secondary"> {values.code} </Badge>}
          </h5>
          <ListGroup tag="div">
            <Row form>
              <Col xs={6} sm={4}>
                <CustomInput
                  label={"Prénom " + title}
                  type="text"
                  name="firstName"
                  id="firstName"
                  handleChange={handleChange}
                  value={values.firstName}
                  maxLength={50}
                  invalid={!!errors.firstName}
                  feedBack={errors.firstName}
                  isDisabled={isDisabled}
                />
              </Col>
              <Col xs={6} sm={4}>
                <CustomInput
                  label={"Nom " + title}
                  type="text"
                  name="lastName"
                  id="lastName"
                  handleChange={handleChange}
                  value={values.lastName}
                  maxLength={50}
                  invalid={!!errors.lastName}
                  feedBack={errors.lastName}
                  isDisabled={isDisabled}
                />
              </Col>
              <Col xs={6} sm={4}>
                <CustomInput
                  label="Email"
                  type="email"
                  name="email"
                  id="email"
                  handleChange={handleChange}
                  value={values.email}
                  maxLength={30}
                  invalid={!!errors.email}
                  feedBack={errors.email}
                  isDisabled={isDisabled}
                />
              </Col>
              <Col xs={6} sm={4}>
                <CustomInput
                  label="Téléphone"
                  type="text"
                  name="mobile"
                  id="mobile"
                  handleChange={handleChange}
                  value={values.mobile}
                  maxLength={13}
                  invalid={!!errors.mobile}
                  feedBack={errors.mobile}
                  isDisabled={isDisabled}
                />
              </Col>
              <Col xs={6} sm={4}>
                <CustomInput
                  label="Date de naissance"
                  type="date"
                  name="birthdate"
                  id="birthdate"
                  handleChange={this.handleChangeBirthdate}
                  value={moment(values.birthdate).format("YYYY-MM-DD")}
                  invalid={!!errors.birthdate}
                  feedBack={errors.birthdate}
                  isDisabled={isDisabled}
                />
              </Col>
              <Col xs={6} sm={4}>
                <AutoCompleteInput
                  label="Addresse"
                  type="text"
                  name="address"
                  id="address"
                  handleChange={this.handleChangeAddress}
                  value={values.address}
                  maxLength={50}
                  invalid={!!errors.address}
                  feedBack={errors.address}
                  isDisabled={isDisabled}
                />
              </Col>

              {/* DOWN HERE DRIVER ONLY FIELDS */}
              {(userType === userTypes.DRIVER ||
                userType === userTypes.MINI_ADMIN) && (
                <React.Fragment>
                  <Col xs={6} sm={4}>
                    <CustomInput
                      label="Siren"
                      type="text"
                      name="siren"
                      id="siren"
                      handleChange={handleChange}
                      value={values.siren}
                      maxLength={9}
                      invalid={!!errors.siren}
                      feedBack={errors.siren}
                      isDisabled={isDisabled}
                    />
                  </Col>
                  <Col xs={6} sm={4}>
                    <CustomInput
                      label="numéro de tva"
                      type="text"
                      name="vat"
                      id="vat"
                      handleChange={handleChange}
                      value={values.vat}
                      maxLength={13}
                      invalid={!!errors.vat}
                      feedBack={errors.vat}
                      isDisabled={isDisabled}
                    />
                  </Col>
                  <Col xs={6} sm={4}>
                    <CustomInput
                      label="RIB"
                      type="text"
                      name="rib"
                      id="rib"
                      handleChange={handleChange}
                      value={values.rib}
                      invalid={!!errors.rib}
                      feedBack={errors.rib}
                      isDisabled={isDisabled}
                    />
                  </Col>
                </React.Fragment>
              )}
              {/* <Col xs={6} sm={4}>
                <CustomInput
                  label="pays"
                  type="select"
                  name="country"
                  id="country"
                  handleChange={handleChange}
                  value={values.country}
                  isDisabled={isDisabled}
                >
                  {this.state.countries.map((c, i) => (
                    <option key={i} value={c}>
                      {c}
                    </option>
                  ))}
                </CustomInput>
              </Col>
              <Col xs={6} sm={4}>
                <CustomInput
                  label="ville"
                  type="select"
                  name="city"
                  id="city"
                  handleChange={handleChange}
                  value={values.city}
                  isDisabled={isDisabled}
                >
                  {this.state.cities.map((c, i) => (
                    <option key={i} value={c}>
                      {c}
                    </option>
                  ))}
                </CustomInput>
              </Col> */}

              <Col xs={6} sm={4}>
                <CustomInput
                  label={"statut " + title}
                  type="select"
                  name="status"
                  id="status"
                  isDisabled={isDisabled}
                  handleChange={handleChange}
                  value={values.status}
                  className={
                    values.status == ACTIVE
                      ? "text-success text-uppercase"
                      : "text-danger text-uppercase"
                  }
                >
                  <option
                    key={1}
                    value={ACTIVE}
                    className="text-success text-uppercase"
                  >
                    ACTIF
                  </option>
                  <option
                    key={2}
                    value={INACTIVE}
                    className="text-danger text-uppercase"
                  >
                    INACTIF
                  </option>
                </CustomInput>
              </Col>
              {(userType === userTypes.DRIVER ||
                userType === userTypes.MINI_ADMIN) && (
                <React.Fragment>
                  <Col xs={6} sm={4}>
                    <FormGroup col>
                      <Label for="zone" className="list-group__label">
                        Zone <span className="">* </span>
                      </Label>
                      <ZonesSelect
                        setRef={this.setRef}
                        defaultValue={values.zone}
                        onChange={handleChange}
                        invalid={!!errors.zone}
                        isDisabled={isDisabled}
                      />
                      <FormFeedback>{errors.zone}</FormFeedback>
                    </FormGroup>
                  </Col>

                  <Col xs={6} sm={4}>
                    <CustomInput
                      label="activé par"
                      type="input"
                      handleChange={null}
                      isDisabled={true}
                      value={
                        values &&
                        values.acceptedBy &&
                        values.acceptedBy.firstName
                          ? values.acceptedBy.firstName +
                            " " +
                            values.acceptedBy.lastName
                          : values.createdBy.firstName +
                            " " +
                            values.createdBy.lastName
                      }
                    />
                  </Col>
                </React.Fragment>
              )}
              {userType === userTypes.CUSTOMER && (
                <>
                  {/* <Col xs={6} sm={4}>
                    <CustomInput
                      label="Email vérifié"
                      type="select"
                      isDisabled={true}
                      handleChange={null}
                      value={values.emailVerified ? "Oui" : "Non"}
                      className={
                        values.emailVerified
                          ? "text-success text-uppercase"
                          : "text-danger text-uppercase"
                      }
                    >
                      <option
                        key={1}
                        value={"Oui"}
                        className="text-success text-uppercase"
                      >
                        Oui
                      </option>
                      <option
                        key={2}
                        value={"Non"}
                        className="text-danger text-uppercase"
                      >
                        Non
                      </option>
                    </CustomInput>
                  </Col> */}
                  <Col xs={6} sm={4}>
                    <CustomInput
                      label="Téléphone vérifié"
                      type="select"
                      isDisabled={true}
                      handleChange={null}
                      value={values.mobileVerified ? "Oui" : "Non"}
                      className={
                        values.mobileVerified
                          ? "text-success text-uppercase"
                          : "text-danger text-uppercase"
                      }
                    >
                      <option
                        key={1}
                        value={"Oui"}
                        className="text-success text-uppercase"
                      >
                        Oui
                      </option>
                      <option
                        key={2}
                        value={"Non"}
                        className="text-danger text-uppercase"
                      >
                        Non
                      </option>
                    </CustomInput>
                  </Col>
                  {values && values.promotion && (
                    <Col xs={6} sm={4}>
                      <Label className="list-group__label" for={"kbis"}>
                        CODE PARRAIN
                      </Label>

                      <Link
                        className="link"
                        to={`/code-promos/details/${values.promotion.id}`}
                      >
                        <div
                          className="form-control text-success"
                          style={{
                            backgroundColor: "#e9ecef",
                          }}
                        >
                          {values.promotion.code}
                        </div>
                      </Link>
                    </Col>
                  )}
                </>
              )}
              {/* File uploads */}
              {(userType === userTypes.DRIVER ||
                userType === userTypes.MINI_ADMIN) && (
                <>
                  <Col xs={6} sm={4} className="mb-4">
                    <Label className="list-group__label" for={"kbis"}>
                      KABIS
                    </Label>
                    <div
                      className={
                        isDisabled
                          ? "d-flex justify-content-start"
                          : "d-flex justify-content-between"
                      }
                    >
                      <div>
                        {this.renderFilePreview(values.kbis, values.kbisToSend)}
                        <input
                          type="file"
                          name="kbis"
                          id="kbis"
                          ref={this.setRef}
                          accept="image/*,application/pdf"
                          hidden="hidden"
                          onChange={this.handleFileUpload}
                        />
                      </div>
                      {!isDisabled && (
                        <Button
                          color="secondary"
                          outline
                          disabled={isDisabled}
                          onClick={this.triggerFileUpload("kbis")}
                          style={{
                            height: 30,
                            marginTop: 45,
                            marginRight: 20,
                          }}
                        >
                          Remplacer
                          <i className="fa fa-arrow-circle-up ml-3" />
                        </Button>
                      )}
                    </div>
                  </Col>

                  {/*   <Col xs={6} sm={4} className="mb-4">
                    <Label className="list-group__label" for={"criminalRecord"}>
                      Casier judiciaire
                    </Label>
                    <div
                      className={
                        isDisabled
                          ? "d-flex justify-content-start"
                          : "d-flex justify-content-between"
                      }>
                      <div>
                        {this.renderFilePreview(
                          values.criminalRecord,
                          values.criminalRecordToSend
                        )}

                        <input
                          type="file"
                          name="criminalRecord"
                          id="criminalRecord"
                          ref={this.setRef}
                          accept="image/*,application/pdf"
                          hidden="hidden"
                          onChange={this.handleFileUpload}
                        />
                      </div>
                      {!isDisabled && (
                        <Button
                          color="secondary"
                          outline
                          disabled={isDisabled}
                          onClick={this.triggerFileUpload("criminalRecord")}
                          style={{
                            height: 30,
                            marginTop: 45,
                            marginRight: 20,
                          }}>
                          Remplacer
                          <i className="fa fa-arrow-circle-up ml-3" />
                        </Button>
                      )}
                    </div>
                  </Col> */}

                  <Col xs={6} sm={4} className="mb-4">
                    <Label className="list-group__label" for={"nidFront"}>
                      PIÈCE D'IDENTITÉ RÉCTO
                    </Label>
                    <div
                      className={
                        isDisabled
                          ? "d-flex justify-content-start"
                          : "d-flex justify-content-between"
                      }
                    >
                      <div>
                        {this.renderFilePreview(
                          values.nidFront,
                          values.nidFrontToSend
                        )}

                        <input
                          type="file"
                          name="nidFront"
                          id="nidFront"
                          ref={this.setRef}
                          accept="image/*,application/pdf"
                          hidden="hidden"
                          onChange={this.handleFileUpload}
                        />
                      </div>
                      {!isDisabled && (
                        <Button
                          color="secondary"
                          outline
                          disabled={isDisabled}
                          onClick={this.triggerFileUpload("nidFront")}
                          style={{
                            height: 30,
                            marginTop: 45,
                            marginRight: 20,
                          }}
                        >
                          Remplacer
                          <i className="fa fa-arrow-circle-up ml-3" />
                        </Button>
                      )}
                    </div>
                  </Col>
                  <Col xs={6} sm={4} className="mb-3">
                    <Label className="list-group__label" for={"nidBack"}>
                      PIÈCE D'IDENTITÉ VERSO
                    </Label>
                    <div
                      className={
                        isDisabled
                          ? "d-flex justify-content-start"
                          : "d-flex justify-content-between"
                      }
                    >
                      <div>
                        {this.renderFilePreview(
                          values.nidBack,
                          values.nidBackToSend
                        )}

                        <input
                          type="file"
                          name="nidBack"
                          id="nidBack"
                          ref={this.setRef}
                          accept="image/*, application/pdf"
                          hidden="hidden"
                          onChange={this.handleFileUpload}
                        />
                      </div>
                      {!isDisabled && (
                        <Button
                          color="secondary"
                          outline
                          disabled={isDisabled}
                          onClick={this.triggerFileUpload("nidBack")}
                          style={{
                            height: 30,
                            marginTop: 45,
                            marginRight: 20,
                          }}
                        >
                          Remplacer
                          <i className="fa fa-arrow-circle-up ml-3" />
                        </Button>
                      )}
                    </div>
                  </Col>
                  {/*  <Col xs={6} sm={4} className="mb-3">
                    <Label className="list-group__label" for={"contract"}>
                      CONTRAT
                    </Label>
                    <div
                      className={
                        !isDisabled && values.contract
                          ? "d-flex justify-content-between"
                          : values.contract
                          ? "d-flex justify-content-start"
                          : "d-flex justify-content-center"
                      }>
                      {values.contract && (
                        <div>
                          {this.renderFilePreview(
                            values.contract,
                            values.contractToSend
                          )}
                        </div>
                      )}
                      <input
                        type="file"
                        name="contract"
                        id="contract"
                        ref={this.setRef}
                        accept="application/pdf"
                        hidden="hidden"
                        onChange={this.handleFileUpload}
                      />
                      {!isDisabled && (
                        <Button
                          color="secondary"
                          outline
                          disabled={isDisabled}
                          onClick={this.triggerFileUpload("contract")}
                          style={{
                            height: 30,
                            marginTop: 45,
                            marginRight: 20,
                          }}>
                          Remplacer
                          <i className="fa fa-arrow-circle-up ml-3" />
                        </Button>
                      )}
                    </div>
                  </Col> */}
                </>
              )}
            </Row>
          </ListGroup>
        </Form>
      </>
    );
  }
}

UserUpdateForm.propTypes = {
  handleChange: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.object,
  toggleInput: PropTypes.func,
  isDisabled: PropTypes.bool,
  setFieldValue: PropTypes.func,
  handleReset: PropTypes.func,
  userType: PropTypes.string,
  title: PropTypes.string,
};

export default UserUpdateForm;
