import gql from "graphql-tag";
import RejectionsDetails from "./components/orders/RejectionsDetails";

export const KITCHEN_CATEGORIES = gql`
  query KitchenCategories($value: String) {
    kitchenCategories(value: $value) {
      id
      name
      image
      status
    }
  }
`;

export const MENU_CATEGORIES = gql`
  query MenuCategories($value: String) {
    menuCategories(value: $value) {
      id
      name
    }
  }
`;

export const STORE_CATEGORIES = gql`
  query storeTypes($value: String) {
    storeTypes(value: $value) {
      id
      name
      status
    }
  }
`;
export const FRANCHISES_LIST = gql`
  query franchisesList {
    franchisesList {
      id
      name
      status
    }
  }
`;
export const SUB_CATEGORIES = gql`
  query SubCategories(
    $value: String
    $menuCategory: ID
    $skip: Boolean = false
  ) {
    subCategories(value: $value, menuCategory: $menuCategory) @skip(if: $skip) {
      id
      name
      menuCategory {
        id
        name
      }
    }
  }
`;

export const ME = gql`
  {
    me {
      id
      email
      mobile
      address {
        country
        city
      }
      profile {
        firstName
        lastName
        photoUrl
      }
      zone {
        id
        name
        cities
      }
      role
      settings {
        deliveryAmount
        stripeDeliveryAmount
        kmDeliveryPrice
        apiClientId
        apiSecret
        stuartIsEnabled
        production
        deliveryMode
      }
    }
  }
`;
export const COUNT_MENU_REQUEST = gql`
  query CountMenuRequests(
    $value: String
    $startDate: Float
    $endDate: Float
    $status: String
  ) {
    countMenuRequests(
      value: $value
      status: $status
      startDate: $startDate
      endDate: $endDate
    )
  }
`;

export const COUNT_DRIVER_REQUEST = gql`
  query CountDriverRequests(
    $value: String
    $startDate: Float
    $endDate: Float
    $status: String
  ) {
    countDriverRequests(
      value: $value
      status: $status
      startDate: $startDate
      endDate: $endDate
    )
  }
`;

export const COUNT_STORE_REQUEST = gql`
  query CountStoreRequests(
    $value: String = null
    $startDate: Float
    $endDate: Float
    $status: String
    $withContract: Boolean
  ) {
    countStoreRequests(
      value: $value
      startDate: $startDate
      endDate: $endDate
      status: $status
      withContract: $withContract
    )
  }
`;

export const fragments = {
  orderDetails: gql`
    fragment OrderItemDetails on Order {
      id
      code
      status
      note
      amount
      totalAmount
      discount
      vat
      createdAt
      acceptedByStore
      deliveryMode
      assignedAt
      preparedAt
      driverReachedToStoreAt
      secondDriverReachedToStoreAt
      takenAt
      takenBySecondDriverAt
      deliveredAt
      receivedBy
      rejectedAt
      rejected
      rejectedReason
      swappedAt
      swapped
      swapReason
      swapRequestsLength
      swapRequestedAt
      swapRequestExpiredAt
      deliveryAmount
      deliveryType
      apiResponseLivringInfos {
        driver {
          firstName
          lastName
          phone
        }

        trackingUrl
      }
      deliveryAddress {
        address
        details
      }
      driver {
        id
        profile {
          firstName
          lastName
        }
      }
      oldDriver {
        id
        profile {
          firstName
          lastName
        }
      }
      store {
        id
        name
        preparationTime
      }
      customer {
        id
        profile {
          firstName
          lastName
        }
      }
      promotion {
        id
        code
        type
        value
      }
      orderItems {
        id
        name
        note
        price
        quantity
        total
        addons {
          id
          name
          items {
            id
            name
            price
          }
        }
      }
      ...OrderRejectionsDetails
    }
    ${RejectionsDetails.fragments.orderRejectionsDetails}
  `,
  fakeOrderDetails: gql`
    fragment OrderItemDetails on FakeOrder {
      id
      code
      status
      note
      amount
      totalAmount
      discount
      vat
      createdAt
      noStripe
      noDriver
      deliveryAmount
      deliveryType
      promotion {
        id
        code
        type
        value
      }
      deliveryAddress {
        address
        details
      }
      driver {
        id
        profile {
          firstName
          lastName
        }
      }
      oldDriver {
        id
        profile {
          firstName
          lastName
        }
      }
      store {
        id
        name
        preparationTime
      }
      customer {
        id
        profile {
          firstName
          lastName
        }
      }
      promotion {
        id
        code
        type
        value
      }
      orderItems {
        id
        name
        note
        price
        quantity
        total
        addons {
          id
          name
          items {
            id
            name
            price
          }
        }
      }
    }
  `,
};

export const ORDER = gql`
  query order($id: ID!) {
    order(id: $id) {
      id
      ...OrderItemDetails
    }
  }
  ${fragments.orderDetails}
`;

export const FAKE_ORDER = gql`
  query fakeOrder($id: ID!) {
    fakeOrder(id: $id) {
      id
      ...OrderItemDetails
    }
  }
  ${fragments.fakeOrderDetails}
`;

export const ORDERS = gql`
  query Orders(
    $limit: Int!
    $skip: Int!
    $value: String
    $status: String
    $startDate: Float
    $endDate: Float
    $swapped: Boolean
    $ready: Boolean
    $rejected: Boolean
    $deliveryMode: String
  ) {
    orders(
      limit: $limit
      skip: $skip
      value: $value
      status: $status
      startDate: $startDate
      endDate: $endDate
      swapped: $swapped
      ready: $ready
      rejected: $rejected
      deliveryMode: $deliveryMode
    ) {
      id
      ...OrderItemDetails
    }
  }
  ${fragments.orderDetails}
`;

export const FAKE_ORDERS = gql`
  query fakeOrders(
    $limit: Int!
    $skip: Int!
    $value: String
    $status: String
    $startDate: Float
    $endDate: Float
    $noDriver: Boolean
    $noStripe: Boolean
  ) {
    fakeOrders(
      limit: $limit
      skip: $skip
      value: $value
      status: $status
      startDate: $startDate
      endDate: $endDate
      noStripe: $noStripe
      noDriver: $noDriver
    ) {
      id
      ...OrderItemDetails
    }
  }
  ${fragments.fakeOrderDetails}
`;

export const COUNT_ORDER = gql`
  query CountOrders(
    $value: String
    $status: String
    $startDate: Float
    $endDate: Float
    $swapped: Boolean
    $ready: Boolean
    $rejected: Boolean
    $deliveryMode: String
  ) {
    countOrders(
      value: $value
      status: $status
      startDate: $startDate
      endDate: $endDate
      swapped: $swapped
      ready: $ready
      rejected: $rejected
      deliveryMode: $deliveryMode
    )
  }
`;

export const FAKE_COUNT_ORDER = gql`
  query countFakeOrders(
    $value: String
    $status: String
    $startDate: Float
    $endDate: Float
    $noStripe: Boolean
    $noDriver: Boolean
  ) {
    countFakeOrders(
      value: $value
      status: $status
      startDate: $startDate
      endDate: $endDate
      noStripe: $noStripe
      noDriver: $noDriver
    )
  }
`;

export const COUNT_NOTIFICATION = gql`
  query CountNotifications($looked: Boolean) {
    countNotifications(looked: $looked)
  }
`;
