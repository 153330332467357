import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import FallbackSpinner from "./components/common/FallbackSpinner";
import DriverStripe from "./containers/driver/DriverStripe";
import MiniAdminStripe from "./containers/admin/AdminStripe";

// Private Routes
const Home = React.lazy(() => import("./containers/Home"));
const Admins = React.lazy(() => import("./containers/admin/Admins"));
const CreateAdmin = React.lazy(() => import("./containers/admin/CreateAdmin"));
const AdminDetails = React.lazy(() =>
  import("./containers/admin/AdminDetails")
);
const MiniAdmins = React.lazy(() => import("./containers/manager/Admins"));
const CreateMiniAdmin = React.lazy(() =>
  import("./containers/manager/CreateAdmin")
);
const MiniAdminDetails = React.lazy(() =>
  import("./containers/manager/AdminDetails")
);
const Zones = React.lazy(() => import("./containers/zone/Zones"));
const CreateZone = React.lazy(() => import("./containers/zone/CreateZone"));
const ZoneDetails = React.lazy(() => import("./containers/zone/ZoneDetails"));
const Streamers = React.lazy(() => import("./containers/streamer/Streamers"));
const CreateStreamer = React.lazy(() =>
  import("./containers/streamer/CreateStreamer")
);
const StreamerDetails = React.lazy(() =>
  import("./containers/streamer/StreamerDetails")
);
const StoreRequests = React.lazy(() =>
  import("./containers/request/StoreSignUpRequests")
);

const DriversRequests = React.lazy(() =>
  import("./containers/request/DriverSignUpRequests")
);
const NewStoreRequestDetails = React.lazy(() =>
  import("./containers/request/newStoreDetails/StoreRequestDetails")
);
const DriverRequestDetails = React.lazy(() =>
  import("./containers/request/DriverRequestDetails")
);
const MenuRequestDetails = React.lazy(() =>
  import("./containers/request/MenuRequestDetails")
);

const UpdateMenuRequest = React.lazy(() =>
  import("./containers/request/UpdateMenuRequest")
);
const CustomerDetails = React.lazy(() =>
  import("./containers/customer/CustomerDetails")
);

const MenuRequests = React.lazy(() =>
  import("./containers/request/MenuRequests")
);
const Customers = React.lazy(() => import("./containers/customer/Customers"));

// STORES
const Stores = React.lazy(() => import("./containers/store/Stores"));

const StoreDetails = React.lazy(() =>
  import("./containers/store/StoreDetails")
);
const StoreStripe = React.lazy(() => import("./containers/store/StoreStripe"));
const CreateStore = React.lazy(() => import("./containers/store/CreateStore"));
const Franchises = React.lazy(() =>
  import("./containers/franchise/Franchises")
);

const CreateFranchise = React.lazy(() =>
  import("./containers/franchise/CreateFranchise")
);
const MenuDetails = React.lazy(() => import("./containers/store/MenuDetails"));
const MenuDeletedDetails = React.lazy(() =>
  import("./containers/store/MenuDeletedDetails")
);

const UpdateMenu = React.lazy(() => import("./containers/store/UpdateMenu"));

// DRIVERS
const Drivers = React.lazy(() => import("./containers/driver/Driver"));
const DriversMap = React.lazy(() => import("./containers/driver/DriversMap"));
const DriverDetails = React.lazy(() =>
  import("./containers/driver/DriverDetails")
);
const CreateDriver = React.lazy(() =>
  import("./containers/driver/CreateDriver")
);
//PAYMENT
const Transaction = React.lazy(() =>
  import("./containers/payment/Transaction")
);
const StoreTransactionDetails = React.lazy(() =>
  import("./containers/payment/StoreTransactionDetails")
);
const DriverTransactionDetails = React.lazy(() =>
  import("./containers/payment/DriverTransactionDetails")
);
const Payout = React.lazy(() => import("./containers/payment/Payout"));

const PayoutDetails = React.lazy(() =>
  import("./containers/payment/PayoutDetails")
);
const Report = React.lazy(() => import("./containers/payment/Report"));

const TransferError = React.lazy(() =>
  import("./containers/payment/TransferError")
);

const Profile = React.lazy(() => import("./containers/Profile"));
const Settings = React.lazy(() => import("./containers/Settings"));
const SettingsMiniAdmin = React.lazy(() =>
  import("./containers/SettingsMiniAdmin")
);

const ChangePassword = React.lazy(() => import("./containers/ChangePassword"));

// CODE PROMO
const CodePromos = React.lazy(() =>
  import("./containers/codePromos/Promotions")
);
const PromotionDetails = React.lazy(() =>
  import("./containers/codePromos/PromotionDetails")
);
const CreatePromotion = React.lazy(() =>
  import("./containers/codePromos/CreatePromotion")
);
const UpdatePromotion = React.lazy(() =>
  import("./containers/codePromos/UpdatePromotion")
);

// KITCHEN
const KitchenCategories = React.lazy(() =>
  import("./containers/kitchen/KitchenCategories")
);
const KitchenCategoryDetails = React.lazy(() =>
  import("./containers/kitchen/KitchenCategoryDetails")
);
const SubCategoryDetails = React.lazy(() =>
  import("./containers/kitchen/SubCategoryDetails")
);
const MenuTypeDetails = React.lazy(() =>
  import("./containers/kitchen/MenuTypeDetails")
);
const MenuCategories = React.lazy(() =>
  import("./containers/kitchen/MenuCategories")
);
const MenuCategoryDetails = React.lazy(() =>
  import("./containers/kitchen/MenuCategoryDetails")
);
const SubCategories = React.lazy(() =>
  import("./containers/kitchen/SubCategories")
);
const MenuTypes = React.lazy(() => import("./containers/kitchen/MenuTypes"));
const CreateMenuType = React.lazy(() =>
  import("./containers/kitchen/CreateMenuType")
);
const UpdateMenuType = React.lazy(() =>
  import("./containers/kitchen/UpdateMenuType")
);

// ORDERS
const OutgoingOrders = React.lazy(() =>
  import("./containers/orders/OutgoingOrder")
);

//Rescued
const RescuedOrders = React.lazy(() =>
  import("./containers/rescue/RescuedOrders")
);
//const AllOrders = React.lazy(() => import("./containers/orders/AllOrders"));
const CurrentOrders = React.lazy(() =>
  import("./containers/orders/CurrentOrders")
);
const RejectedOrders = React.lazy(() =>
  import("./containers/orders/RejectedOrders")
);
const RescindOrders = React.lazy(() =>
  import("./containers/orders/RescindOrders")
);
// const ProgrammedOrders = React.lazy(() =>
//   import("./containers/orders/ProgrammedOrders")
// );
const PendingOrders = React.lazy(() =>
  import("./containers/orders/PendingOrders")
);
const PreparedOrders = React.lazy(() =>
  import("./containers/orders/PreparedOrders")
);
const SalvageOrders = React.lazy(() =>
  import("./containers/orders/SalvageOrders")
);
const DelivredOrders = React.lazy(() =>
  import("./containers/orders/DelivredOrders")
);
const OrderDetails = React.lazy(() =>
  import("./containers/orders/OrderDetails")
);

// HISTORICS
const ProductHistorics = React.lazy(() =>
  import("./containers/historic/ProductHistorics")
);
const PrivateRoute = React.lazy(() => import("./containers/PrivateRoute"));
//NOTIFICATIONS

const Notifications = React.lazy(() =>
  import("./containers/notifications/Notifications")
);

const FakeOrders = React.lazy(() =>
  import("./containers/fakeOrders/FakeOrders")
);

const FakeOrderDetails = React.lazy(() =>
  import("./containers/fakeOrders/FakeOrderDetails")
);
// Public Routes
const Login = React.lazy(() => import("./containers/Login"));
const ForgotPassword = React.lazy(() => import("./containers/ForgotPassword"));

const RestaurantCategories = React.lazy(() =>
  import("./containers/store/storeCategories")
);

const publicRoutes = [
  {
    path: "/connexion",
    component: Login,
    exact: true,
  },
  {
    path: "/mot-de-passe-oublie",
    component: ForgotPassword,
  },
];

const privateRoutes = [
  //HOME
  {
    path: "/accueil/profil",
    component: Profile,
    exact: true,
  },
  {
    path: "/accueil/Paramètres",
    component: Settings,
    exact: true,
  },
  {
    path: "/accueil/paramètres-gestionnaire",
    component: SettingsMiniAdmin,
    exact: true,
  },
  {
    path: "/accueil/changer-mot-de-passe",
    component: ChangePassword,
    exact: true,
  },
  {
    path: "/accueil/notifications",
    component: Notifications,
    exact: true,
  },
  //GESTIONNAIRES

  {
    path: "/gestionnaires/pages/:page",
    component: MiniAdmins,
    exact: true,
  },

  {
    path: "/gestionnaires/nouveau",
    component: CreateMiniAdmin,
    exact: true,
  },
  {
    path: "/gestionnaires/details/:id",
    component: MiniAdminDetails,
    exact: true,
  },
  // zones
  {
    path: "/zones/nouveau",
    component: CreateZone,
    exact: true,
  },
  {
    path: "/zones/details/:id",
    component: ZoneDetails,
    exact: true,
  },
  {
    path: "/zones/pages/:page",
    component: Zones,
    exact: true,
  },
  //ADMINS
  {
    path: "/administrateurs/pages/:page",
    component: Admins,
    exact: true,
  },

  {
    path: "/administrateurs/nouveau",
    component: CreateAdmin,
    exact: true,
  },
  {
    path: "/administrateurs/details/:id",
    component: AdminDetails,
    exact: true,
  },
  //DIFFUSEURS
  {
    path: "/diffuseurs/pages/:page",
    component: Streamers,
    exact: true,
  },

  {
    path: "/diffuseurs/nouveau",
    component: CreateStreamer,
    exact: true,
  },
  {
    path: "/diffuseurs/details/:id",
    component: StreamerDetails,
    exact: true,
  },
  //REQUESTS
  {
    path: "/demandes/inscription-restaurants/pages/:page",
    component: StoreRequests,
    exact: true,
  },
  {
    path: "/demandes/inscription-livreurs/pages/:page",
    component: DriversRequests,
    exact: true,
  },
  {
    path: "/demandes/inscription-restaurants/details/:id/:fromNotifications?",
    component: NewStoreRequestDetails,
    exact: true,
  },
  {
    path: "/demandes/inscription-livreurs/details/:id",
    component: DriverRequestDetails,
    exact: true,
  },
  {
    path: "/demandes/approbation-carte/details/:id",
    component: MenuRequestDetails,
    exact: true,
  },
  {
    path: "/demandes/approbation-carte/pages/:page",
    component: MenuRequests,
    exact: true,
  },
  {
    path: "/demandes/approbation-carte/modifier/:id",
    component: UpdateMenuRequest,
    exact: true,
  },
  // STORES
  {
    path: "/restaurants/pages/:page",
    component: Stores,
    exact: true,
  },
  {
    path: "/restaurants/créer",
    component: CreateStore,
    exact: true,
  },
  {
    path: "/restaurants/details/:id/:scrollToProducts?",
    component: StoreDetails,
    exact: true,
  },
  {
    path: "/restaurants/stripe/:id",
    component: StoreStripe,
    exact: true,
  },
  {
    path: "/restaurants/produits/details/:id",
    component: MenuDetails,
    exact: true,
  },
  {
    path: "/restaurants/produits/supprimés/:id",
    component: MenuDeletedDetails,
    exact: true,
  },
  {
    path: "/restaurants/produits/modifier/:id",
    component: UpdateMenu,
    exact: true,
  },
  {
    path: "/restaurants/categories-restaurants",
    component: RestaurantCategories,
    exact: true,
  },
  // POMOTIONS
  {
    path: "/code-promos/pages/:page",
    component: CodePromos,
    exact: true,
  },
  {
    path: "/code-promos/modifier/:id",
    component: UpdatePromotion,
    exact: true,
  },
  {
    path: "/code-promos/nouveau",
    component: CreatePromotion,
    exact: true,
  },
  {
    path: "/code-promos/details/:id",
    component: PromotionDetails,
    exact: true,
  },
  {
    path: "/clients/pages/:page",
    component: Customers,
    exact: true,
  },

  {
    path: "/clients/details/:id",
    component: CustomerDetails,
    exact: true,
  },
  {
    path: "/livreurs/pages/:page",
    component: Drivers,
    exact: true,
  },
  {
    path: "/livreurs/details/:id",
    component: DriverDetails,
    exact: true,
  },
  {
    path: "/livreurs/stripe/:id",
    component: DriverStripe,
    exact: true,
  },
  {
    path: "/gestionnaires/stripe/:id",
    component: MiniAdminStripe,
    exact: true,
  },
  {
    path: "/livreurs/créer",
    component: CreateDriver,
    exact: true,
  },
  {
    path: "/paiements/transaction/pages/:page",
    component: Transaction,
    exact: true,
  },
  {
    path: "/paiements/transaction-store/details/:id",
    component: StoreTransactionDetails,
    exact: true,
  },
  {
    path: "/paiements/transaction-driver/details/:id",
    component: DriverTransactionDetails,
    exact: true,
  },
  {
    path: "/paiements/virements/pages/:page",
    component: Payout,
    exact: true,
  },
  {
    path: "/paiements/virements/details/:id",
    component: PayoutDetails,
    exact: true,
  },
  {
    path: "/paiements/rapports",
    component: Report,
    exact: true,
  },
  {
    path: "/paiements/transferts-bloqués/pages/:page",
    component: TransferError,
    exact: true,
  },
  //Kitchens
  {
    path: "/categories/spécialité/details/:id",
    component: KitchenCategoryDetails,
    exact: true,
  },
  {
    path: "/categories/spécialité",
    component: KitchenCategories,
    exact: true,
  },

  {
    path: "/categories/menus/details/:id",
    component: MenuCategoryDetails,
    exact: true,
  },
  {
    path: "/categories/sous-categories/details/:id",
    component: SubCategoryDetails,
    exact: true,
  },
  {
    path: "/categories/menus",
    component: MenuCategories,
    exact: true,
  },
  {
    path: "/categories/sous-categories",
    component: SubCategories,
    exact: true,
  },
  {
    path: "/categories/sous-sous-categories/pages/:page",
    component: MenuTypes,
    exact: true,
  },
  {
    path: "/categories/sous-sous-categories/nouveau",
    component: CreateMenuType,
    exact: true,
  },
  {
    path: "/categories/sous-sous-categories/modifier/:id",
    component: UpdateMenuType,
    exact: true,
  },
  {
    path: "/categories/sous-sous-categories/details/:id",
    component: MenuTypeDetails,
    exact: true,
  },

  //Orders
  // {
  //   path: "/commandes/toutes-commandes/pages/:page",
  //   component: AllOrders,
  //   exact: true
  // },
  {
    path: "/commandes/commandes-en-attente/pages/:page",
    component: PendingOrders,
    exact: true,
  },
  {
    path: "/commandes/commandes-en-cours/pages/:page",
    component: CurrentOrders,
    exact: true,
  },
  {
    path: "/commandes/commandes-pretes/pages/:page",
    component: PreparedOrders,
    exact: true,
  },

  {
    path: "/commandes/commandes-livrees/pages/:page",
    component: DelivredOrders,
    exact: true,
  },
  {
    path: "/commandes/commandes /pages/:page",
    component: PreparedOrders,
    exact: true,
  },
  {
    path: "/commandes/commandes-en-livraison/pages/:page",
    component: OutgoingOrders,
    exact: true,
  },
  {
    path: "/commandes/details/:id",
    component: OrderDetails,
    exact: true,
  },
  {
    path: "/commandes/commandes-refusees/pages/:page",
    component: RejectedOrders,
    exact: true,
  },
  {
    path: "/commandes/commandes-annulees/pages/:page",
    component: RescindOrders,
    exact: true,
  },
  {
    path: "/historique/pages/:page",
    component: ProductHistorics,
    exact: true,
  },
  {
    path: "/tentativescommandes/pages/:page",
    component: FakeOrders,
    exact: true,
  },
  {
    path: "/tentativescommandes/details/:id",
    component: FakeOrderDetails,
    exact: true,
  },
  {
    path: "/commandes/commandes-en-livraison/pages/:page",
    component: OutgoingOrders,
    exact: true,
  },
  {
    path: "/commandes/commandes-livrees/pages/:page",
    component: DelivredOrders,
    exact: true,
  },
  {
    path: "/commandes/commandes-sauvees/pages/:page",
    component: SalvageOrders,
    exact: true,
  },

  //Franchises

  {
    path: "/franchises/pages/:page",
    component: Franchises,
    exact: true,
  },
  {
    path: "/franchises/ajouter",
    component: CreateFranchise,
    exact: true,
  },
  {
    path: "/franchises/modifier/:id",
    component: CreateFranchise,
    exact: true,
  },

  //Sauvetage

  {
    path: "/sauvetage/pages/:page",
    component: RescuedOrders,
    exact: true,
  },
  {
    path: "/sauvetage/details/:id",
    component: OrderDetails,
    exact: true,
  },
  {
    path: "/carte",
    component: DriversMap,
    exact: true,
  },
  {
    path: "/(|accueil)/",
    component: Home,
    exact: true,
  },
];

class Routes extends React.PureComponent {
  render() {
    return (
      <React.Suspense fallback={<FallbackSpinner />}>
        <HashRouter>
          <Switch>
            {publicRoutes.map((route) => (
              <Route key={route.path} {...route} />
            ))}
            <PrivateRoute>
              <Switch>
                {privateRoutes.map((route) => (
                  <Route key={route.path} {...route} />
                ))}
              </Switch>
            </PrivateRoute>
          </Switch>
        </HashRouter>
      </React.Suspense>
    );
  }
}

export default Routes;
