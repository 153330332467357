import {
  days,
  FRENCH_DATE_TIME_FORMAT,
  PROJECT_NAME,
  routeToTitle,
  SEANCE,
} from "./constants";
import moment from "./moment";

export const setDocumentTitle = (subTitle) => {
  document.title = `${PROJECT_NAME} | ${subTitle}`;
};

export const pathToTitle = (pathname) => {
  const paths = pathname.split("/");
  if (
    paths.length > 2 &&
    (paths[1] === "categories" || paths[1] === "demandes")
  )
    return routeToTitle[paths[2]];
  return routeToTitle[pathname.split("/")[1] || pathname.replace("/", "")];
};

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getRequest = (body = null, method = "POST") => {
  const request = {
    headers,
    method,
  };
  const token = localStorage.getItem("token");
  if (token) request.headers.Authorization = token;
  if (body && method === "POST") request.body = JSON.stringify(body);
  return request;
};

export const formattingMobile = (mobile) => {
  if (!mobile) return null;
  const countryCode = mobile.substring(0, 3);
  return mobile.length === 13
    ? `${countryCode}${mobile.substring(3, 4)}${mobile.substring(
        4,
        6
      )}${mobile.substring(6, 8)}${mobile.substring(8, 11)}${mobile.substring(
        10,
        12
      )}`
    : `${countryCode}${mobile.substring(3, 5)}${mobile.substring(
        5,
        7
      )}${mobile.substring(7, 9)}${mobile.substring(9, mobile.length)}`;
};

export const average = (obj) => {
  if (obj.total == 0) return 0;
  else {
    let moy = obj.partial / obj.total;
    return moy;
  }
};

export const averageSystem = () => {
  return 5;
};

export const totalAverage = (...ratingItems) => {
  const nestedItems = [];
  ratingItems.forEach((item) => {
    if (typeof item.total === "undefined")
      Object.keys(item).forEach((key) => {
        if (key !== "__typename") nestedItems.push(item[key]);
      });
  });
  const items = [
    ...ratingItems.filter((item) => typeof item.total !== "undefined"),
    ...nestedItems,
  ];
  let sum = 0;
  const avgs = items.map((item) => {
    sum += item.coef;
    return item.total === 0 ? 0 : (item.partial / item.total) * item.coef;
  });
  return (avgs.reduce((a, b) => a + b) / sum) * 5;
};

export const createNewImage = (image) =>
  image
    ? image.indexOf("blob") !== -1
      ? image
      : `${image}?${new Date().getMilliseconds()}`
    : null;

export const getDaySeances = (weekDays = days, seance = SEANCE) =>
  weekDays.map((item) => `${item}{${seance}}`).join("");

export const convertTime = (time) => {
  return time
    ? moment(time).isDST()
      ? moment(time)
          .subtract(1, "hour")
          .format(FRENCH_DATE_TIME_FORMAT)
      : moment(time).format(FRENCH_DATE_TIME_FORMAT)
    : null;
};

export const getCompleteAddress = (address, city, country) => {
  let completeAddress = "";
  if (address) {
    completeAddress = completeAddress + address;
  }
  if (city) {
    completeAddress = completeAddress + city;
  }

  if (country) {
    completeAddress = completeAddress + country;
  }

  return completeAddress;
};

export const getMinTime = (first, second) => {
  return Date.parse(`01/01/2011 ${first}`) > Date.parse(`01/01/2011 ${second}`)
    ? first
    : second;
};

export const getMaxTime = (first, second) => {
  return Date.parse(`01/01/2011 ${first}`) > Date.parse(`01/01/2011 ${second}`)
    ? second
    : first;
};

export function getPayoutStatus(status) {
  switch (status) {
    case "paid":
      return "Payé";
    case "pending":
      return "En attente";
    case "in_transit":
      return "En transit";
    case "canceled":
      return "Annulé";
    case "failed":
      return "Échoué";
    default:
      return "En attente";
  }
}

export function getTransactionStatus(payout) {
  if (payout && payout.payout) return "Traité";
  else return "En attente";
}

export function getTransferError(message) {
  if (message.includes("No such destination")) {
    let splits = message.split(":");
    return "Ce compte stripe est introuvable:" + splits[1];
  } else if (message.includes("must be greater"))
    return "Prélèvement en attente de solde suffisant.";
  else return message;
}

export function getPayoutFailureMessage(code, message) {
  switch (code) {
    case "account_closed":
      return "Le compte bancaire est fermé.";
    case "account_frozen":
      return "Le compte bancaire est bloqué.";
    case "bank_account_restricted":
      return "Le compte bancaire a des restrictions sur le type ou le nombre de paiements autorisés. Cela indique normalement que le compte bancaire est un compte d'épargne ou un autre compte non-chèque.";
    case "bank_ownership_changed":
      return "Le compte bancaire de destination n'est plus valide car sa succursale a changé de propriétaire.";
    case "could_not_process":
      return "La banque n'a pas pu traiter ce paiement. ";
    case "debit_not_authorized":
      return "Les transactions par débit ne sont pas approuvées sur le compte bancaire. (Stripe exige que les comptes bancaires soient configurés pour les paiements de crédit et de débit.)";
    case "declined":
      return "La banque a refusé ce virement. Veuillez contacter la banque avant de réessayer.";
    case "insufficient_funds":
      return "Votre compte Stripe n'a pas les fonds suffisants pour couvrir le paiement";
    case "invalid_account_number":
      return "Le numéro de routage semble correct, mais le numéro de compte n'est pas valide.";
    case "incorrect_account_holder_name":
      return "Votre banque nous a informés que le nom du titulaire du compte bancaire dans le fichier est incorrect";
    case "invalid_currency":
      return "La banque n'a pas pu traiter ce paiement en raison de sa devise. Cela est probablement dû au fait que le compte bancaire ne peut pas accepter les paiements dans cette devise.";
    case "no_account":
      return "Les coordonnées bancaires du dossier sont probablement incorrectes. Aucun compte bancaire n'a pu être identifié avec ces informations.";
    case "unsupported_card":
      return "La banque ne prend plus en charge les paiements sur cette carte.";
    default:
      return message;
  }
}

export const getDeletedBy = (deletedBy) => {
  switch (deletedBy) {
    case "MANAGER":
      return "Résponsable";
    case "ADMIN":
      return "Administrateur";
    case "SUPER_ADMIN":
      return "Administrateur";
    case "DRIVER":
      return "Livreur";
    case "CUSTOMER":
      return "Client";
    default:
      return "";
  }
};

export function capitalize(s) {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function formatMobile(mobile) {
  if (mobile && typeof mobile === "string") {
    return mobile.replace("+33", "0");
  } else return "";
}
