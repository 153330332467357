import gql from "graphql-tag";
import PropTypes from "prop-types";
import React from "react";
import {
  FRENCH_DATE_TIME_FORMAT,
  REJECTED_BY_ADMIN,
  REJECTED_BY_CUSTOMER,
  REJECTED_BY_DRIVER,
  REJECTED_BY_STORE,
  REJECTED_BY_SYSTEM
} from "../../utils/constants";
import moment from "../../utils/moment";
import OrderItemDetails from "../common/OrderItemDetails";

class RejectionsDetails extends React.PureComponent {
  static propTypes = {
    order: PropTypes.object.isRequired
  };

  static fragments = {
    orderRejectionsDetails: gql`
      fragment OrderRejectionsDetails on Order {
        id
        status
        rejectedAt
        rejected
        rejectedReason
        store {
          id
          name
        }
        customer {
          id
          profile {
            firstName
            lastName
          }
        }
        driver {
          id
          profile {
            firstName
            lastName
          }
        }
      }
    `
  };

  getRejectedBy = () => {
    const { customer, driver, store, status } = this.props.order;
    let rejectedBy = null;
    switch (status) {
      case REJECTED_BY_CUSTOMER:
        rejectedBy = `${customer.profile.firstName} ${
          customer.profile.lastName
        }`;
        break;
      case REJECTED_BY_DRIVER:
        rejectedBy = `${driver.profile.firstName} ${driver.profile.lastName}`;
        break;
      case REJECTED_BY_STORE:
        rejectedBy = store.name;
        break;
      case REJECTED_BY_ADMIN:
        rejectedBy = "Administrateur";
        break;
      case REJECTED_BY_SYSTEM:
        rejectedBy = "Système";
        break;
      default:
    }
    return rejectedBy;
  };

  render() {
    const { rejectedAt, rejectedReason, status } = this.props.order;
    const rejectedBy = this.getRejectedBy();

    return (
      <React.Fragment>
        <OrderItemDetails
          label="Annulée le"
          value={moment(rejectedAt).isDST()?moment(rejectedAt).subtract(1, "hour").format(FRENCH_DATE_TIME_FORMAT):moment(rejectedAt).format(FRENCH_DATE_TIME_FORMAT)}
        />
        {rejectedBy && (
          <OrderItemDetails label="Annulée par" value={rejectedBy} />
        )}
        {rejectedReason && status !== REJECTED_BY_DRIVER && (
          <OrderItemDetails
            label="Raison d'annulation"
            value={rejectedReason}
          />
        )}
      </React.Fragment>
    );
  }
}

export default RejectionsDetails;
