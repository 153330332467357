/* eslint-disable react/jsx-no-bind */
import { Formik } from "formik";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import React from "react";
import { compose, graphql } from "react-apollo";
import { withRouter } from "react-router";
import { Button, CardBody } from "reactstrap";
import * as Yup from "yup";
import Alert from "../../utils/Alert";
import {
  MOBILE_REGEX_INCLUSIVE,
  REAL_NAME_REGEX,
  SIREN_REGEX,
  userTypes,
  VAT_REGEX,
} from "../../utils/constants";
import { formatMobile, formattingMobile } from "../../utils/functions";
import Address from "../common/Address";
import ConfirmationModal from "../common/ConfirmationModal";
import Content from "../common/Content";
import UserUpdateForm from "./UserUpdateForm";

const driverValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(REAL_NAME_REGEX, "Veuillez saisir un prénom valide")
    .required("Ce champ est obligatoire"),
  lastName: Yup.string()
    .matches(REAL_NAME_REGEX, "Veuillez saisir un nom valide")
    .required("Ce champ est obligatoire"),
  email: Yup.string()
    .email("Cet Email est invalide")
    .required("Ce champ est obligatoire"),
  mobile: Yup.string()
    .matches(MOBILE_REGEX_INCLUSIVE, "Ce numéro de téléphone est invalide")
    .required("Ce champ est obligatoire"),
  vat: Yup.string()
    .matches(VAT_REGEX, "Doit se composer de 'FR' et 11 chiffres")
    .required("Ce champ est obligatoire"),
  siren: Yup.string()
    .matches(SIREN_REGEX, "Doit se composer de 9 chiffres")
    .required("Ce champ est obligatoire"),
  vehicle: Yup.string()
    .min(2, "Doit contenir au moin 2 caractères")
    .max(50)
    .required("Ce champ est obligatoire"),
});
const userValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(REAL_NAME_REGEX, "Veuillez saisir un prénom valide")
    .required("Ce champ est obligatoire"),
  lastName: Yup.string()
    .matches(REAL_NAME_REGEX, "Veuillez saisir un nom valide")
    .required("Ce champ est obligatoire"),
  email: Yup.string()
    .email("Cet Email est invalide")
    .required("Ce champ est obligatoire"),
  mobile: Yup.string()
    .matches(MOBILE_REGEX_INCLUSIVE, "Ce numéro de téléphone est invalide")
    .required("Ce champ est obligatoire"),
});

const USER_FIELDS = `
  id
  profile {
    firstName
    lastName
    photoUrl
    birthdate
  }
  address {
    ...AddressDetails
  }
  mobile
  rib
  dob{
    day
    month
    year
  }
  email
  emailVerified
  mobileVerified
  status
  createdAt
`;

class UserUpdate extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    userType: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    updateUser: PropTypes.func,
    softDeleteAccount: PropTypes.func,
  };

  static defaultProps = {
    className: "",
    title: "",
    userType: "USER",
  };

  static fragments = {
    customerDetailsUpdate: gql`
			fragment CustomerDetailsUpdate on Customer {
		${USER_FIELDS}
		code
    promotion {
      id
      code
    }
			}
			${Address.fragments.addressDetails}
		`,
    responsibleDetailsUpdate: gql`
			fragment ResponsibleDetailsUpdate on Manager {
        ${USER_FIELDS}
			}
			${Address.fragments.addressDetails}
		`,
    adminDetailsUpdate: gql`
      fragment AdminDetailsUpdate on Admin {
        id
        profile {
          firstName
          lastName
          photoUrl
          birthdate
        }
        address {
          ...AddressDetails
        }
        kbis
        nidFront
        nidBack
        zone {
          id
          name
        }
        vat
        siren
        rib
        mobile
        email
        emailVerified
        mobileVerified
        status
        createdAt
        createdBy {
          id
          profile {
            firstName
            lastName
          }
        }
        stripeAccountId
      }
      ${Address.fragments.addressDetails}
    `,
    driverDetailsUpdate: gql`
    fragment DriverDetailsUpdate on Driver  {
      ${USER_FIELDS}
      kbis
      nidFront
      nidBack
      contract
      vat
      siren
      zone{
        id 
        name
      }
    
      criminalRecord
      vehicle
      acceptedBy {
        id
        profile {
          firstName
          lastName
        }
      }
    }
    ${Address.fragments.addressDetails}
  `,
  };

  constructor(props) {
    super(props);
    const {
      user: {
        id,
        profile: { firstName, lastName, birthdate, photoUrl }, // birthdate not done
        email,
        mobile,
        address,
        emailVerified,
        mobileVerified,
        dob,
        status,
        code,
        rib,
        siren,
        vat,
        criminalRecord,
        kbis,
        nidFront,
        nidBack,
        contract,
        vehicle,
        acceptedBy,
        createdBy,
        zone,
      },
    } = props;

    const sharedValues = {
      id,
      firstName,
      lastName,
      birthdate,
      email,
      dob: dob ? dob : null,
      rib: rib ? rib : null,
      mobile: formattingMobile(mobile),
      photoUrl: photoUrl ? photoUrl : null,
      photoUrlToSend: photoUrl ? photoUrl : null,
      photoUrlChanged: false,
      status,
      emailVerified,
      mobileVerified,
      zone: zone && zone.id ? zone.id : null,
    };
    if (address) {
      sharedValues.address = address.address ? address.address : "";
      sharedValues.country = address.country ? address.country : "France";
      sharedValues.city = address.city ? address.city : "Paris";
      if (address.location) {
        sharedValues.location = {
          lat: address.location.lat ? address.location.lat : 0,
          lng: address.location.lng ? address.location.lng : 0,
        };
      } else {
        sharedValues.location = { lat: 0, lng: 0 };
      }
    } else {
      sharedValues.address = "";
      sharedValues.country = "France";
      sharedValues.city = "Paris";
      sharedValues.location = { lat: 0, lng: 0 };
    }

    if (
      props.userType === userTypes.DRIVER ||
      props.userType === userTypes.MINI_ADMIN
    )
      this.state = {
        values: {
          ...sharedValues,
          siren,
          vat,
          rib: rib ? rib : null,
          dob: dob ? dob : {},
          kbis: kbis ? kbis : null,
          kbisToSend: kbis ? kbis : null,
          kbisChanged: false,
          criminalRecord: criminalRecord ? criminalRecord : null,
          criminalRecordToSend: criminalRecord ? criminalRecord : null,
          criminalRecordChanged: false,
          nidFront: nidFront ? nidFront : null,
          nidFrontToSend: nidFront ? nidFront : null,
          nidFrontChanged: false,
          nidBack: nidBack ? nidBack : null,
          nidBackToSend: nidBack ? nidBack : null,
          nidBackChanged: false,
          contract: contract ? contract : null,
          contractToSend: contract ? contract : null,
          contractChanged: false,
          vehicle,
          birthdate: birthdate ? birthdate : null,
          acceptedBy:
            acceptedBy && acceptedBy.profile ? acceptedBy.profile : null,
          createdBy: createdBy && createdBy.profile ? createdBy.profile : null,
        },
        isDisabled: true,
        isInputModified: false,
        isVisible: false,
        deleteModalVisible: false,
        loadingDelete: false,
        code,
      };
    else
      this.state = {
        values: { ...sharedValues },
        isDisabled: true,
        isInputModified: false,
        isVisible: false,
        deleteModalVisible: false,
        loadingDelete: false,
      };
    if (props.userType === userTypes.CUSTOMER) {
      this.state.values.code = this.props.user.code;
      this.state.values.promotion = this.props.user.promotion;
    }
  }

  toggleInput = () => {
    this.setState({ isDisabled: !this.state.isDisabled });
  };

  // modal functions
  onCancel = () => {
    this.setState({
      isVisible: false,
    });
  };
  openModal = () => {
    this.setState({
      isVisible: true,
      error: null,
    });
  };
  // end modal functions

  // api functions
  updateUser = (userUpdates, setSubmitting) => {
    this.props
      .updateUser({
        variables: {
          updatedUser: userUpdates,
          requestType: this.props.userType,
        },
      })
      .then((response) => {
        setSubmitting(false);
        this.setState({ isVisible: false });
        if (response.data) {
          this.toggleInput();
          Alert.success(
            `La mise à jour ${this.props.title} a été traitée avec succès`
          );
        } else {
          Alert.error(
            `La mise à jour ${this.props.title} a echoué essayer une autre fois`
          );
        }
      })
      .catch((e) => {
        setSubmitting(false);
        this.setState({ isVisible: false });

        if (
          e &&
          e.graphQLErrors &&
          e.graphQLErrors[0] &&
          e.graphQLErrors[0].message
        )
          Alert.error(e.graphQLErrors[0].message);
        else
          Alert.error(
            "La mise à jour " +
              this.props.title +
              " a echoué essayer une autre fois"
          );
      });
  };
  driverSetupRequest = (values) => {
    const {
      id,
      firstName,
      lastName,
      photoUrlToSend,
      photoUrlChanged,
      birthdate,
      email,
      rib,
      mobile,
      siren,
      vat,
      status,
      vehicle,
      country,
      city,
      address,
      location,
      kbisChanged,
      kbisToSend,
      nidFrontToSend,
      nidFrontChanged,
      nidBackToSend,
      nidBackChanged,
      contractToSend,
      contractChanged,
      criminalRecordToSend,
      criminalRecordChanged,
      zone,
    } = values;
    const profile = {
      firstName,
      lastName,
      birthdate,
    };
    if (photoUrlChanged) profile.photoUrl = photoUrlToSend;
    const driverSchema = {
      id,
      profile, // birthdate not done
      email,
      rib,
      mobile,
      siren: parseInt(siren),
      vat,
      status,
      vehicle,
      address: { country, city, address, location },
      zone,
    };
    if (kbisChanged) driverSchema.kbis = kbisToSend;
    if (nidFrontChanged) driverSchema.nidFront = nidFrontToSend;
    if (nidBackChanged) driverSchema.nidBack = nidBackToSend;
    if (contractChanged) driverSchema.contract = contractToSend;
    if (criminalRecordChanged)
      driverSchema.criminalRecord = criminalRecordToSend;
    return driverSchema;
  };
  userSetupRequest = (values) => {
    const {
      id,
      firstName,
      lastName,
      photoUrlToSend,
      photoUrlChanged,
      email,
      mobile,
      status,
      country,
      city,
      address,
      location,
      rib,
      birthdate,
    } = values;

    const profile = {
      firstName,
      lastName,
      birthdate,
    };
    if (photoUrlChanged) profile.photoUrl = photoUrlToSend;
    const userSchema = {
      id,
      profile, // birthdate not done
      email,
      mobile,
      status,
      rib,
      address: { country, city, address, location },
    };
    return userSchema;
  };

  onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    if (
      this.props.userType === userTypes.DRIVER ||
      this.props.userType === userTypes.MINI_ADMIN
    ) {
      const updatedDriver = this.driverSetupRequest(values);
      this.updateUser(updatedDriver, setSubmitting);
    } else {
      const updatedUser = this.userSetupRequest(values);
      this.updateUser(updatedUser, setSubmitting);
    }
  };
  openDeleteModal = () => this.setState({ deleteModalVisible: true });
  onCancelDelete = () => this.setState({ deleteModalVisible: false });
  onSubmitDelete = () => {
    const { loadingDelete } = this.state;
    if (!loadingDelete) {
      this.setState({ loadingDelete: true });

      const { id } = this.state.values;
      const variables = { type: this.props.userType };
      switch (this.props.userType) {
        case userTypes.CUSTOMER: {
          variables.customerId = id;
          break;
        }
        case userTypes.DRIVER: {
          variables.driverId = id;
          break;
        }
        case userTypes.ADMIN: {
          variables.adminId = id;
          break;
        }
        case userTypes.MINI_ADMIN: {
          variables.adminId = id;
          break;
        }

        default:
          return;
      }

      this.props
        .softDeleteAccount({ variables })
        .then(() => {
          this.setState({ loadingDelete: false, deleteModalVisible: false });
          Alert.success(
            `${
              this.props.userType === userTypes.ADMIN
                ? "l'administrateur"
                : "le " + this.props.title
            } a été supprimé avec succès.`
          );
          this.props.history.goBack();
        })
        .catch((e) => {
          console.log(e);
          this.setState({ loadingDelete: false, deleteModalVisible: false });
          if (e && e.graphQLErrors && e.graphQLErrors.length > 0)
            Alert.error(e.graphQLErrors[0].message);
        });
    }
  };
  openStripeForm = () =>
    this.props.user.userType === userTypes.DRIVER
      ? this.props.history.push(`/livreurs/stripe/${this.props.user.id}`)
      : this.props.history.push(`/gestionnaires/stripe/${this.props.user.id}`);

  render() {
    const {
      isDisabled,
      values,
      isVisible,
      deleteModalVisible,
      loadingDelete,
    } = this.state;
    const {
      userType,
      title,
      user: { stripeAccountId },
    } = this.props;
    console.log({ stripeAccountId });
    return (
      <Formik
        initialValues={values}
        validateOnBlur={true}
        validateOnMount={true}
        validationSchema={
          userType == userTypes.DRIVER
            ? driverValidationSchema
            : userValidationSchema
        }
        onSubmit={this.onSubmit}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          handleReset,
          setFieldValue,
        }) => (
          <React.Fragment>
            <Content>
              <CardBody>
                <UserUpdateForm
                  title={title}
                  userType={userType}
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  isDisabled={isDisabled}
                  toggleInput={this.toggleInput}
                  handleReset={handleReset}
                  setFieldValue={setFieldValue}
                  openDeleteModal={this.openDeleteModal}
                  openStripeForm={this.openStripeForm}
                  hasStripe={stripeAccountId ? true : false}
                  stripeAccountId={stripeAccountId}
                />
                <div className="mt-4 d-flex flex-row justify-content-center">
                  <Button
                    onClick={handleReset}
                    className="mr-sm-2"
                    outline
                    color="secondary"
                    disabled={isDisabled && !isSubmitting}
                  >
                    ANNULER
                  </Button>
                  <Button
                    onClick={this.openModal}
                    className="text-white"
                    color="success"
                    disabled={isDisabled && !isSubmitting}
                  >
                    ENREGISTRER
                  </Button>
                </div>
              </CardBody>
            </Content>
            <ConfirmationModal
              size="lg"
              isVisible={isVisible}
              loading={isSubmitting}
              onConfirm={handleSubmit}
              onCancel={this.onCancel}
              title={"Enregistrer les modifications"}
              text={"voulez vous vraiment enregistrer ces modifications?"}
            />
            <ConfirmationModal
              size="md"
              isVisible={deleteModalVisible}
              loading={loadingDelete}
              onConfirm={this.onSubmitDelete}
              onCancel={this.onCancelDelete}
              title={`Supprimer le ${title}`}
              text={`Voulez vous vraiment supprimer ${
                this.props.userType == userTypes.ADMIN ? "cet" : "ce"
              } ${title}?`}
            />
          </React.Fragment>
        )}
      </Formik>
    );
  }
}

const UPDATE_USER = gql`
  mutation updateUser($updatedUser: UserUpdateInput!, $requestType: String!) {
    updateUser(updatedUser: $updatedUser, requestType: $requestType) {
      id
      __typename
    }
  }
`;

const DESACTIVATE_USER = gql`
  mutation softDeleteAccount(
    $type: String!
    $adminId: String
    $customerId: String
    $driverId: String
  ) {
    softDeleteAccount(
      type: $type
      adminId: $adminId
      driverId: $driverId
      customerId: $customerId
    )
  }
`;

export default compose(
  graphql(UPDATE_USER, {
    name: "updateUser",
  }),
  graphql(DESACTIVATE_USER, { name: "softDeleteAccount" }),
  withRouter
)(UserUpdate);
