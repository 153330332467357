import React, { Component } from "react";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import { Col } from "reactstrap";

import ItemDetails from "./ItemDetails";

export class Adresse extends Component {
  static propTypes = {
    address: PropTypes.object
  };

  static fragments = {
    addressDetails: gql`
      fragment AddressDetails on Address {
        zipCode
        country
        city
        address
        location {
          lat
          lng
        }
      }
    `
  };

  render() {
    const { address } = this.props;
    if (!address) return null;
    return (
      <>
        <Col sm={6} xs={12}>
          <ItemDetails label="Pays" value={address.country} />
        </Col>
        {address.city && (
          <Col sm={6} xs={12}>
            <ItemDetails label="Ville" value={address.city} />
          </Col>
        )}
        {address.zipCode && (
          <Col sm={6} xs={12}>
            <ItemDetails label="Code postal" value={address.zipCode} />
          </Col>
        )}
        {address.address && (
          <Col sm={6} xs={12}>
            <ItemDetails label="Adresse" value={address.address} />
          </Col>
        )}
      </>
    );
  }
}

export default Adresse;
