import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import CollapseItemDetails from "./CollapseItemDetails";

export default class OrderItemDetails extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    value: PropTypes.any,
    labelKey: PropTypes.string,
    isOldDriver: PropTypes.bool,
  };

  _textArea = (note) => <textarea className="comment" value={note} />;

  _suplementrender = (value) =>
    !Array.isArray(value)
      ? value
      : value[0] &&
        value.map(({ name, addons, note, price, quantity, total }, index) => (
          <CollapseItemDetails
            name={name}
            addons={addons}
            note={note}
            price={price}
            quantity={quantity}
            total={total}
            key={index}
          />
        ));

  _renderOldDriver = () => {
    const { labelKey, value, isOldDriver } = this.props;
    const checkForColor = labelKey !== "address" && isOldDriver;

    return (
      <span
        style={{ whiteSpace: "pre-line" }}
        className={`col-md-3  ${labelKey == "note" ? "comment" : ""} ${
          checkForColor ? "old-driver" : ""
        }  `}
      >
        {value.oldDriver}
      </span>
    );
  };

  _renderNewDriver = () => {
    const { labelKey, value, isOldDriver } = this.props;

    return (
      <span
        style={{ whiteSpace: "pre-line" }}
        className={`col-md-4  ${labelKey == "note" ? "comment" : ""} `}
      >
        {isOldDriver ? value.newDriver : this._suplementrender(value)}
      </span>
    );
  };

  render() {
    const { label, value, isOldDriver } = this.props;

    return (
      (value || value === 0) && (
        <div className="row mb-3">
          <span className="col-md-5 bold text-uppercase">{label}</span>

          {isOldDriver && this._renderOldDriver()}
          {this._renderNewDriver()}
        </div>
      )
    );
  }
}
